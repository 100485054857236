<div style="padding-top:2px; border: solid 0.5px;border-color: #e1dddd;margin-top: 10px;">
  <div class="container">
    <div class="details">
      <div class="row" style="background-color: #0d4f9e; margin-bottom: 20px;">
        <div class="col">

          <h5 class="modal-title" style="color:#FFFFFF;text-align: center;">Demo Modal Buttons</h5>

        </div>
      </div>
<div class="form">
  <button class="btn btn-primary" (click)="openmodal(estimationmodal)">Estimation List</button>&nbsp;

  <button class="btn btn-primary"  (click)="openmodal(discountmodal)">Discount Authorization</button>&nbsp;

  <button class="btn btn-primary" (click)="openmodal(consignmentmodalmodal)">Consignment Stock</button>&nbsp;

  <button class="btn btn-primary" (click)="openmodal(consignmentitemdetailmodal)">Consignment Item Detail</button>&nbsp;

  <button class="btn btn-primary" (click)="openmodal(ordercancelmodal)">Order Cancellation</button>&nbsp;

  <button class="btn btn-primary" (click)="openlockunlock()">Order Lock & Unlock</button>&nbsp;
<div class="row" style="padding-top: 3%;">
  <button class="btn btn-primary" (click)="openmodal(schemeregistermodal)">Scheme Registration</button>&nbsp;

  <button class="btn btn-primary" (click)="openmodal(schemeregisterdetailmodal)">Scheme Registration Details</button>&nbsp;
</div>


</div>

    </div>
  </div>
</div>
<!--Estimation Modal start-->
<ng-template #estimationmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Sales Estimation</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-estimation-list></app-estimation-list>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Estimation Modal End-->


<!--Discount Modal start-->
<ng-template #discountmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Discount Authorization</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-discount-authorization></app-discount-authorization>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Discount Modal End-->

<!--Ordercancel Modal start-->
<ng-template #ordercancelmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Discount Authorization</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-order-cancellation></app-order-cancellation>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Ordercancel Modal End-->

<!--Consignment-Stock Modal start-->
<ng-template #consignmentmodalmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Consignment Stock</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-consigment-stock></app-consigment-stock>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Consignment-Stock Modal End-->

<!--Consignment-item-detail Modal start-->
<ng-template #consignmentitemdetailmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Consignment Item Detail</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-consignment-item-detail></app-consignment-item-detail>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Consignment-item-detail Modal End-->



<!--Scheme Register Modal start-->
<ng-template #schemeregistermodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Scheme Registration</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-scheme-registration></app-scheme-registration>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Scheme Register Modal End-->

<!--Scheme Register-detail Modal start-->
<ng-template #schemeregisterdetailmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

  <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e;">
      <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Scheme Registration Detail</h5>
      <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
  </div>
  <div class="modal-body" style="padding: 0px;">
      <div class="cds-billing">
          <div class="content-holder">
              <div class="row">
                  <div class="col-12">
                      <app-scheme-registration-details></app-scheme-registration-details>
                  </div>
              </div>
          </div>
      </div>
  </div>

</ng-template>
<!--Scheme Register-detail Modal End-->

