<div id="data-grid-demo"  class="consigmentstock">

  <dx-data-grid
    id="gridContainer"
    [showBorders]="true"
    (onSelectionChanged)="selectionChanged($event)"
  >
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing
      mode="cell"
      [allowUpdating]="true"

    >
    </dxo-editing>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxi-column *ngFor="let data of consigmentstockhead" dataField="{{data}}" [width]="auto" caption="{{data}}" [allowEditing]="true" alignment="center" backgroundcolor="#000">
    </dxi-column>

  </dx-data-grid>
  <div style="text-align: right; padding:10px; ">
    <button class="btn " style="background-color: #0d4f9e;color:#fff">Ok</button>&nbsp;
    <button class="btn " style="background-color: #0d4f9e;color:#fff" >Cancel</button>&nbsp;
  </div>
</div>

