import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-tabs-modal',
  templateUrl: './tabs-modal.component.html',
  styleUrls: ['./tabs-modal.component.scss']
})
export class TabsModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
