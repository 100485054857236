import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderCancellationComponent } from './order-cancellation/order-cancellation.component';
import { OrderLockUnlockComponent } from './order-lock-unlock/order-lock-unlock.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { SchemeRegistrationComponent } from './scheme-registration/scheme-registration.component';
import { SchemeRegistrationDetailsComponent } from './scheme-registration-details/scheme-registration-details.component';
import { DxDataGridModule } from 'devextreme-angular'
import {MatRadioModule} from '@angular/material/radio';
import { DxCheckBoxModule } from 'devextreme-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatNativeDateModule,DateAdapter} from '@angular/material/core';
import { DateFormat } from 'src/app/salesorder/sales-order/date-format';



@NgModule({
  declarations: [
    OrderCancellationComponent,
    OrderLockUnlockComponent,
    SchemeRegistrationComponent,
    SchemeRegistrationDetailsComponent

  ],
  providers: [{ provide: DateAdapter, useClass: DateFormat }],
  imports: [
    CommonModule,
    MatTabsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    DxDataGridModule,
    MatRadioModule,
    DxCheckBoxModule,
    MatDatepickerModule,
    MatNativeDateModule



  ],
  exports:[
    OrderCancellationComponent,
    OrderLockUnlockComponent,
    SchemeRegistrationComponent,
    SchemeRegistrationDetailsComponent
  ]
})
export class OrderModule { }
