// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://angulardata.sunwebapps.com/api/',
  baseImageUrl: 'https://angulardata.sunwebapps.com/',
  _noImage: 'no-image.png'
};


export class ILeftMenus {
    BUTTONID: string;
    BUTTON_VISIBLE: string;
    ButtonDescription: string;
    ButtonDescriptionCHINESE: string;
    ButtonDescriptionJOR: string;
    ButtonDescriptionOther: string;
    ButtonDescriptionSAU: string;
    ButtonDescriptionTURKISH: string;
    ButtonImage: string;
    ButtonImagePath: string;
    DetailHolder: string;
    DisplayText: string;
    DisplayTextOther: string;
    MENU_CAPTION_ENG: string;
    MENU_DESC_ARABIC_JOR: string;
    MENU_DESC_ARABIC_SAU: string;
    MENU_ID: string;
    menuurl: string;
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
