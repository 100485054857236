import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheme-registration',
  templateUrl: './scheme-registration.component.html',
  styleUrls: ['./scheme-registration.component.scss']
})
export class SchemeRegistrationComponent implements OnInit {
  selectedItemKeys:any[] = [];
  consigmentstockhead:any[] = ['Code','Unique ID','Units','EMI Value','Paid EMI Value','Start From','End On','Status','Unit/Value','Net Rate','MkgCost','LandedCost','AllocationRef'];

  constructor() { }

  ngOnInit(): void {
  }
  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

}
