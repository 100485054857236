import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchgroup',
  templateUrl: './searchgroup.component.html',
  styleUrls: ['./searchgroup.component.scss']
})
export class SearchgroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
