import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuntechapiService } from 'src/app/suntechapi.service';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss']
})
export class LookupComponent implements OnInit {
  lookupdetails: Array<any>;
  lookupdetailshead: any;
  showPageSizeSelector = true;
  showInfo = true;
  showNavButtons = true;
  constructor(
    private activeModal: NgbActiveModal,
    private suntechApi: SuntechapiService,
  ) {
    this.suntechApi.getLookup().subscribe(resp=>{
      console.log(resp.Result)
    const respw={Result:[
              {
            "Id":0,"Stock Code": "01053912KA20155",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20156",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20157",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20158",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20159",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20160",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20161",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20162",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20163",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053912KA20164",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20165",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20166",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20167",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20168",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20169",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20170",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20171",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20172",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20173",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",
        },
        {
            "Id":0,"Stock Code": "01053914KA20174",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",

        },
        {
            "Id":0,"Stock Code": "0105392KA201753",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",

        },
        {
            "Id":0,"Stock Code": "0105392KA201760",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",

        },
        {
            "Id":0,"Stock Code": "0105392KA201777",
            "Description": "GOLD BRACELET",
            "Category": "GOLD",
            "Division": "D",

        },
    ]}
      console.log(resp.Result)
       resp.Result.forEach((item,i) =>{
        item.Id = i+1;
      });
      // console.log(newArr);
      // console.log(resp.Result);
      this.lookupdetails = resp.Result;
      this.lookupdetailshead = Object.keys(this.lookupdetails[0]);
        // console.log(this.orderedItemsHead);
        this.lookupdetailshead.unshift(this.lookupdetailshead.pop())
        // console.log(this.lookupdetailshead);
        this.lookupdetailshead.pop()
        // this.lookupdetailshead[1]="Stock_Code"
        console.log(this.lookupdetailshead);

    });
  }
  onCellPrepared(e) {
      // console.log('e', e);
      // e.column.filterValue.style.color='red';

  if(e.rowIndex % 2 == 0)
       {
        e.cellElement.bgColor="#f4f5f7";
      console.log(e);
       }
    else{
      console.log('odd');
    }

  }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }

}
