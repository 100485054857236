import { Component, OnInit,enableProdMode } from '@angular/core';
import { Bill, Service } from '../consigment.service';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-estimation-list',
  templateUrl: './estimation-list.component.html',
  styleUrls: ['./estimation-list.component.scss'],
  providers: [Service],
})
export class EstimationListComponent {


  bills: Bill[] | any;

  constructor(service: Service) {
    this.bills = service.getBill();
    console.log(this.bills);
  }

}
