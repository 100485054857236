import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'AMTDECIMALS',
})
export class AMTDECIMALS_Pipe implements PipeTransform {
  transform(value, rule) {
    return (Math.round(value * 100) / 100).toFixed(2)
  }
}

@Pipe({
  name: 'AMTFORMAT_NET',
})
export class AMTFORMAT_NET_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'AMTINTEGERS',
})
export class AMTINTEGERS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'MQTYDECIMALS',
})
export class MQTYDECIMALS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'MQTYFORMAT_NET',
})
export class MQTYFORMAT_NET_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'MQTYINTEGERS',
})
export class MQTYINTEGERS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'MRATEDECIMALS',
})
export class MRATEDECIMALS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'METALRATEFORMAT',
})
export class METALRATEFORMAT_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'MRATEINTEGERS',
})
export class MRATEINTEGERS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'PURITYDECIMALS',
})
export class PURITYDECIMALS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'SQTYDECIMALS',
})
export class SQTYDECIMALS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'SQTYFORMAT_NET',
})
export class SQTYFORMAT_NET_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}

@Pipe({
  name: 'SQTYINTEGERS',
})
export class SQTYINTEGERS_Pipe implements PipeTransform {
  transform(value, rule) {
    return value;
  }
}
