import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoscrComponent } from './poscr/poscr.component';
import { DoctranslogComponent } from './doctranslog/doctranslog.component';
import { AudittrailsComponent } from './audittrails/audittrails.component';
import { EditdltconfComponent } from './editdltconf/editdltconf.component';
import { DxDataGridModule } from 'devextreme-angular';



@NgModule({
  declarations: [
    PoscrComponent,
    DoctranslogComponent,
    AudittrailsComponent,
    EditdltconfComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule,
  ],
  exports:[
    PoscrComponent,
    DoctranslogComponent,
    AudittrailsComponent,
    EditdltconfComponent
  ]
})
export class ReciptsModule { }
