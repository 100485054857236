import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mateditdltconf',
  templateUrl: './mateditdltconf.component.html',
  styleUrls: ['./mateditdltconf.component.scss']
})
export class MateditdltconfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
