<div class="card">
    <ngb-tabset [destroyOnHide]="false">

        <ngb-tab title="Bill To">
            <ng-template ngbTabContent>
                <ul style="padding-top: 10px;">
                    <li>Customer 1</li>
                    <li>Customer 2</li>
                </ul>

            </ng-template>
        </ngb-tab>

        <ngb-tab>
            <ng-template ngbTabTitle>Ship To</ng-template>
            <ng-template ngbTabContent>
                <ul style="padding-top: 10px;">
                    <li>Customer 3</li>
                    <li>Customer 4</li>
                </ul>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>