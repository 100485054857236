import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consignment-item-detail',
  templateUrl: './consignment-item-detail.component.html',
  styleUrls: ['./consignment-item-detail.component.scss']
})
export class ConsignmentItemDetailComponent implements OnInit {
  selectedItemKeys:any[] = [];
  consigmentstockhead:any[] = ['SN','Branch','Stock Code','Allocation Ref','Supplier','Curr','City','Metal Wt','Purity','Pure Wt','Dia Carat','Stone Carat','Dia Amount','Stone Amount','Labour Charge'];

  constructor() { }

  ngOnInit(): void {
  }
  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }
}
