<div class="bar">
    <button type="button" class="btn btn-outline-secondary" (click)="openmodal(crmodal)"><i class="feather icon-plus" style="font-size: 15px; color: green;"></i> </button>
    <button type="button" class="btn btn-outline-secondary" style="margin-left: 10px;" (click)="openmatmodal()"><i class="feather icon-x" style="font-size: 15px; color: red;"></i> </button>
    <button type="button" class="btn btn-outline-secondary" style="margin-left: 10px;">Cash Customer Details</button>
    <!-- <label for="year" style="margin-left: 10px;; font-size: 15px;">Year</label>
    <select type="year" style="margin-left: 10px; font-size: 15px;"><option value="2021">2021</option><option value="2022">2022</option></select>
    <label for="document" style="margin-left: 10px;; font-size: 15px;">Document</label>
    <input type="text" style="margin-left: 10px;; font-size: 15px;">
    <label for="document" style="padding-left: 5px; font-size: 15px;">DRG VOC NO</label>
    <input type="text" style="padding-left: 10px; font-size: 15px;"> -->
    <mat-form-field style="padding-left: 20px;">
        <mat-label>Year</mat-label>
        <mat-select>
        <mat-option >2021</mat-option>
        <mat-option >2022</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Document</mat-label>
        <input type="input" matInput>
    </mat-form-field>
    <mat-form-field>
        <mat-label>DRG VOC NO</mat-label>
        <input type="input" matInput>
    </mat-form-field>

</div>

<ng-template #crmodal let-modal modal-lg>

    <div class="modal-header" style="padding: 5px 10px;background-color:#2e2e2e ;">
        <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Point of Sale Curreny receipt</h5>
        <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
    </div>
    <div class="container" style="background-color: #f4f5f7;">
        <div class="cds-billing">
            <div class="modal-body" style="padding: 0px;background-color:#f4f5f7 ;">
                <div class="content-holder">
                    <div class="row">
                        <div class="col-12">

                            <app-poscr></app-poscr>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-template>
