import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ChatUserListComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';

import { HttpClientModule } from '@angular/common/http';
import { NgxImgZoomModule } from 'ngx-img-zoom';

import { MatInputModule } from '@angular/material/input';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';

import { SuntechapiService } from './suntechapi.service';

/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import {
  NgbButtonsModule,
  NgbDropdownModule,
  NgbTabsetModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DemoposComponent } from './point-of-sale/demopos/demopos.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AMTDECIMALS_Pipe, AMTFORMAT_NET_Pipe } from './amount.pipe';

// devExpress datagrid

import { DxDataGridModule } from 'devextreme-angular';
import { DxiColModule } from 'devextreme-angular/ui/nested';
import { DxoSummaryModule } from 'devextreme-angular/ui/nested';
import { DxiTotalItemModule } from 'devextreme-angular/ui/nested';
import { MainGridPageComponent } from './dashboardpages/main-grid-page/main-grid-page.component';

// Treemodule
import { TreeModule } from "@circlon/angular-tree-component";
import {DragDropModule} from '@angular/cdk/drag-drop';


// import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    DemoposComponent,
    MainGridPageComponent,
    // SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MomentDateModule,
    MatInputModule,
    NgbTabsetModule,
    NgxImgZoomModule,
    MatTabsModule,
    ReactiveFormsModule,
    DxDataGridModule,
    DxiColModule,
    DxoSummaryModule,
    DxiTotalItemModule,
    TreeModule,DragDropModule
  ],
  providers: [
    NavigationItem,
    AMTDECIMALS_Pipe,
    AMTFORMAT_NET_Pipe,
    SuntechapiService,
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
