import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatposcrComponent } from './matposcr/matposcr.component';
import { MataudittrailsComponent } from './mataudittrails/mataudittrails.component';
import { MatdoctranslogComponent } from './matdoctranslog/matdoctranslog.component';
import { MateditdltconfComponent } from './mateditdltconf/mateditdltconf.component';
// import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import { LookupComponent } from '../lookup/lookup/lookup.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import { TexteditNavbarModule } from '../textedit-navbar/textedit-navbar.module';
import {DragDropModule} from '@angular/cdk/drag-drop';


@NgModule({
  declarations: [
    MatposcrComponent,
    MataudittrailsComponent,
    MatdoctranslogComponent,
    MateditdltconfComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,MatButtonModule,MatRippleModule,MatFormFieldModule,MatInputModule,MatSelectModule,MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    // TexteditNavbarModule,
    DragDropModule

  ],
  exports:[
    MatposcrComponent,
    MatdoctranslogComponent,
    MataudittrailsComponent,
    MateditdltconfComponent,
    MatTabsModule,MatButtonModule,MatRippleModule,MatFormFieldModule,MatInputModule,MatSelectModule,MatIconModule
  ],
  entryComponents:[LookupComponent]
})
export class MatreceiptsModule { }
