<!-- <button type="button" class="btn btn-outline-secondary"><i class="fa fa-print" style="font-size: 15px;"></i> <br> Print </button> -->

<div data-role="button-group" data-cls-active="active">

    <button class="ribbon-button">
                <span class="icon">
                  <i class="fa fa-print" style="color: #0d4f9e;"></i>
                </span>
                <span class="caption">Print</span>
            </button>
</div>
<span class="title">Print</span>