import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-poscr',
  templateUrl: './poscr.component.html',
  styleUrls: ['./poscr.component.scss']
})
export class PoscrComponent implements OnInit {
  @Input() modaltool: boolean;

  constructor() { 
    if(this.modaltool === true){
      alert(this.modaltool)
    }
  }

  ngOnInit(): void {
  }

}
