
    <div class="form">
        <mat-form-field style="width: 47%;">
            <mat-label>Password</mat-label>
            <input type="input"  matInput>
        </mat-form-field>
        <mat-form-field style="width: 47%;">
            <mat-label>Reason</mat-label>
            <input type="input" matInput>
        </mat-form-field>                               

    </div>
    <div class="form">
        <mat-form-field style="width: 96%;">
            <mat-label>Description</mat-label>
            <textarea type="input"  matInput></textarea>
        </mat-form-field>
                                      

    </div>

