<!-- <button type="button" class="btn btn-outline-secondary"><i class="fa fa-search" style="font-size: 15px;"></i> <br> Search </button>
<button type="button" class="btn btn-outline-secondary"><i class="fa fa-fast-backward" style="font-size: 15px;"></i> <br> First </button>
<button type="button" class="btn btn-outline-secondary"><i class="fa fa-step-backward" style="font-size: 15px;"></i> <br> Previous </button>
<button type="button" class="btn btn-outline-secondary"><i class="fa fa-step-forward" style="font-size: 15px;"></i> <br> Next </button>
<button type="button" class="btn btn-outline-secondary"><i class="fa fa-fast-forward" style="font-size: 15px;"></i> <br> Last</button> -->

<div data-role="button-group" data-cls-active="active">
    <button class="ribbon-button">
                  <span class="icon">
                      <i class="fa-solid fa-magnifying-glass" style="color: #0d4f9e;"></i>
                    </span>
                  <span class="caption">Search</span>
              </button>
    <button class="ribbon-button">
                  <span class="icon">
                    <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">First</span>
              </button>
    <button class="ribbon-button">
                  <span class="icon">
                    <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">Previous</span>
              </button>
    <button class="ribbon-button">
                  <span class="icon">
                    <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">Next</span>
              </button>
    <button class="ribbon-button">
                  <span class="icon">
                    <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">Last</span>
              </button>
</div>
<span class="title">Search</span>