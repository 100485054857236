import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupComponent } from '../../lookup/lookup/lookup.component';
@Component({
  selector: 'app-matposcr',
  templateUrl: './matposcr.component.html',
  styleUrls: ['./matposcr.component.scss']
})
export class MatposcrComponent implements OnInit {
  @Input() public tableData;
  @Input() public dataIndex;
  @Input() public title;
  @Input() public editData;
  dataIndexSecondary: any;
  dataForm: FormGroup;
  modalRef: any;
  public myWindow = window;
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {
    this.dataForm = this.formBuilder.group({
      branchCode: ['', Validators.required],
      mode: ['', Validators.required],
      debitAccount: ['', Validators.required],
      currency: ['', Validators.required],
      // accountFc: ['', Validators.required],
      // accountLc: ['', Validators.required],
      remarks: ['', Validators.required],
      HSNCode: ['', Validators.required],
      vatNo: ['', Validators.required],
      vatCc: ['', Validators.required],
      vatPercentage: ['', Validators.required],
      totalFc: ['', Validators.required],
      totalLc: ['', Validators.required],
      invoiceNo: ['', Validators.required],
      headerVatAmt: ['', Validators.required],
      invoiceDate: ['', Validators.required],
      amountFc: ['', Validators.required],
      amountLc: ['', Validators.required],
    });
  }
  moveIndex(dataIndex) {
    this.dataIndexSecondary = dataIndex;
    let data = this.tableData.filter((data, index) => {
      return index == this.dataIndexSecondary ? data : ''
    })
    this.dataForm.controls['branchCode'].setValue(data[0].BRANCH_CODE);
    this.dataForm.controls['mode'].setValue(data[0].MODE);
    this.dataForm.controls['currency'].setValue(data[0].CURRENCY_CODE);
    this.dataForm.controls['remarks'].setValue(data[0].REMARKS);
    this.dataForm.controls['HSNCode'].setValue(data[0].HSN_CODE);
    this.dataForm.controls['invoiceNo'].setValue(data[0].INVOICE_NUMBER);
    console.log('====================================');
    console.log(new Date(data[0].INVOICE_DATE));
    console.log('====================================');
    let invoiceDate = new Date(data[0]['INVOICE_DATE']);
    let invoiceDateStr = invoiceDate.toISOString().split('T')[0];
    // this.dob_date.setDate(this.bDay + 1);
    this.dataForm.controls['invoiceDate'].setValue(invoiceDateStr);
    // this.dataForm.controls['invoiceDate'].setValue(new Date(data[0].INVOICE_DATE));
    this.dataForm.controls['headerVatAmt'].setValue(data[0].HEADER_AMOUNT);
    this.dataForm.controls['amountFc'].setValue(data[0].AMOUNTFC);
    this.dataForm.controls['amountLc'].setValue(data[0].AMOUNTCC);
    this.dataForm.controls['totalFc'].setValue(data[0].TOTAL_AMOUNTFC);
    this.dataForm.controls['totalLc'].setValue(data[0].TOTAL_AMOUNTCC);
  }
  ngOnInit(): void {
    console.log(this.tableData);
    console.log(this.dataIndex);
    this.dataIndexSecondary = this.dataIndex;
    let data = this.tableData.filter((data, index) => {
      return index == this.dataIndex ? data : ''
    })
    this.dataForm.controls['branchCode'].setValue(data[0].BRANCH_CODE);
    this.dataForm.controls['mode'].setValue(data[0].MODE);
    this.dataForm.controls['currency'].setValue(data[0].CURRENCY_CODE);
    this.dataForm.controls['remarks'].setValue(data[0].REMARKS);
    this.dataForm.controls['HSNCode'].setValue(data[0].HSN_CODE);
    this.dataForm.controls['invoiceNo'].setValue(data[0].INVOICE_NUMBER);
    let invoiceDate = new Date(data[0]['INVOICE_DATE']);
    let invoiceDateStr = invoiceDate.toISOString().split('T')[0];
    this.dataForm.controls['invoiceDate'].setValue(invoiceDateStr);
    this.dataForm.controls['headerVatAmt'].setValue(data[0].HEADER_AMOUNT);
    this.dataForm.controls['amountFc'].setValue(data[0].AMOUNTFC);
    this.dataForm.controls['amountLc'].setValue(data[0].AMOUNTCC);
    // this.dataForm.controls['branchCode'].setValue(this.tableData.BRANCH_CODE);
    // this.dataForm.controls['mode'].setValue(this.tableData.MODE);
    // this.dataForm.controls['currency'].setValue(this.tableData.CURRENCY_CODE);
    // this.dataForm.controls['remarks'].setValue(this.tableData.REMARKS);
    // this.dataForm.controls['HSNCode'].setValue(this.tableData.HSN_CODE);
    // this.dataForm.controls['invoiceNo'].setValue(this.tableData.INVOICE_NUMBER);
    // this.dataForm.controls['invoiceDate'].setValue(this.tableData.INVOICE_DATE);
    // this.dataForm.controls['headerVatAmt'].setValue(this.tableData.INVOICE_DATE);
  }
  close() {
    this.activeModal.close();
  }
  openlookup() {
    this.modalRef = this.modalService.open(LookupComponent, {
      size: 'md',
      backdrop: true,
      keyboard: false,
      windowClass: 'modal-custom-lookup'
    });
  }
}
