import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  accessToken: any;

  constructor(private router: Router) {
    // this.pageRedirect();
  }

  pageRedirect() {
    this.accessToken = localStorage.getItem('username');

    console.log(this.accessToken);

    if(this.accessToken != null && this.accessToken != "" && this.accessToken != undefined)
    // this.router.navigateByUrl('salesorders')
    this.router.navigate(['salesorders'])
    else
    // this.router.navigateByUrl('')
    this.router.navigate([''])

  }
  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   window.scrollTo(0, 0);
    // });
  }
}
