import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-order-lock-unlock',
  templateUrl: './order-lock-unlock.component.html',
  styleUrls: ['./order-lock-unlock.component.scss']
})
export class OrderLockUnlockComponent implements OnInit {
tasks:any[];
columnhead:any[] = ['No','Branch','Voc Type','Voc No','Voc Date','Year','Salesman','Party Name','Remarks','Order Amount','...'];
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.activeModal.close();
  }
}
