<div class="cds-billing">

    <!-- <div class="row" style="margin-top: 15px;">
        <div class="col-2 ">
            <h5 class="header">Details</h5>
        </div>
    </div> -->
    <div class="details">
        <div class="container">
            <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
                <div class="col" style="padding-top:10px;">

                    <!-- <div class="row" style="padding-top:20px;">
            <div class="col"> -->
                    <!-- <ng-form> -->

                    <div class="row form">
                        <div class="col-2">
                            <label for="branch" class="form-label">Branch</label>
                        </div>
                        <input type="text" id="branch" style="width: 20%;">

                    </div>
                    <div class="row form">
                        <div class="col-2">
                            <label for="modeof" class="label">Mode Of</label>
                        </div>
                        <select type="text" style="font-size: 15px;"><option value="Cash">Cash</option><option value="COD">COD</option></select>
                        <input type="text">
                        <button class="srh_btn"><i class="fa fa-search" aria-hidden="true" style="color: #504848;"></i></button>
                        <input type="text">

                    </div>
                    <div class="row form">
                        <div class="col-2">
                            <label for="modeof" class="label">Debit Account</label>
                        </div>
                        <input type="text">
                        <button class="srh_btn"><i class="fa fa-search" aria-hidden="true" style="color: #504848;"></i></button>
                        <input type="text">
                    </div>
                    <div class="row form">
                        <div class="col-2">
                            <label for="" class="form-label">Curreny</label>
                        </div>
                        <input type="text" style="width:10%">
                        <button class="srh_btn"><i class="fa fa-search" aria-hidden="true" style="color: #504848;"></i></button>
                        <input type="text" style="width:15%">
                        <div class="inpad">
                            <label for="">Amount FC</label>
                        </div>
                        <input type="text" style="width:15%">
                        <div class="inpad">
                            <label for="">Amount LC</label>
                        </div>
                        <input type="text" style="width:15%">
                    </div>

                    <div class="row form">
                        <div class="col-2">
                            <label for="modeof" class="label">Remarks</label>
                        </div>
                        <input type="text" style="width: 62%;">
                    </div>
                    <!-- </ng-form> -->
                </div>

                <!-- </div>
</div> -->

            </div>
        </div>
    </div>

    <div style="padding-top:2px; border: solid 0.5px;border-color: #e1dddd;margin-top: 10px;">
        <div class="container">
            <div class="details">
                <div class="row" style="background-color: #dee1e59e; margin-bottom: 20px;">
                    <div class="col">
                        <h5 style="text-align:center;font-weight:400;font-size: 18px;">VAT Details</h5>
                    </div>
                </div>

                <div class="row">
                    <div class="col-5">
                        <div class="row form">
                            <div class="col-5">
                                <label for="">VAT No</label>
                            </div>
                            <input type="text">
                        </div>
                        <div class="row form">
                            <div class="col-5">
                                <label for="">HSN Code</label>
                            </div>
                            <input type="text">
                        </div>
                        <div class="row form">
                            <div class="col-5">
                                <label for="">Invoice No</label>
                            </div>
                            <input type="text">
                        </div>
                        <div class="row form">
                            <div class="col-5">
                                <label for="">Invoice Date</label>
                            </div>
                            <input type="date">
                        </div>
                    </div>
                    <div class="col">
                        <div class="row" style="padding-bottom: 10px;">
                            <div class="col-7">
                                <button style="width: 40%;border: solid 1px;" disabled="">Type</button>
                                <!-- </div>
            <div  class="col-1"> -->
                                <button style="width: 13%;border: solid 1px;" disabled="">%</button>
                                <!-- </div>
            <div  class="col-3"> -->
                                <button style="width: 31%;border: solid 1px;" disabled="">Amount</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row form">
                                    <div class="col-5">
                                        <label for="">VAT %</label>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="ibox">
                                    </div>
                                </div>
                                <div class="row form">
                                    <div class="col-5">
                                        <label for="">VAT CC</label>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="ibox">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row form">
                                    <div class="col-5">
                                        <label for="" style="margin-left: -3px;">TOTAL FC</label>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="ibox">
                                    </div>
                                </div>
                                <div class="row form">
                                    <div class="col-5">
                                        <label for="" style="margin-left: -3px;">TOTAL LC</label>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="ibox">
                                    </div>
                                </div>
                                <div class="row form">
                                    <div class="col-5">
                                        <label for="" style="margin-left: -42px;">Header VAT Amt</label>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="ibox">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" style="padding: 5px 10px">
        <div style="padding-bottom: 0px;">
            <div style="text-align: right; padding:10px; ">
                <button class="btn btn-danger" style="background-color: #0d4f9e;">Continue</button>&nbsp;
                <button class="btn btn-danger" style="background-color: #0d4f9e;">Save</button>&nbsp;
                <button class="btn btn-danger" style="background-color: #0d4f9e;">Cancel</button>
                <!-- <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">Cancel</button> -->
            </div>
        </div>
    </div>
</div>
