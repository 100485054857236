import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Demo1Component } from './demo1/demo1.component';
import { ConsigmentModule } from '../consigment/consigment.module';
import { OrderModule } from '../order/order.module';



@NgModule({
  declarations: [
    Demo1Component
  ],
  imports: [
    CommonModule,
    ConsigmentModule,
    OrderModule
  ],
  exports:[
    Demo1Component
  ]
})
export class DemopagesModule { }
