
<div class="row mb-3">
  <div class="col-sm-12 border-radius-bltl border-radius-brtr" style="background:#eee;">
      <h5 style="margin-top:9px;">{{pageTitle}}</h5>
  </div>
  </div>

<div class="row">
    <div class="col-md-12 ribbonmenulink">
        <nav data-role="ribbonmenu">
            <ul class="tabs-holder">
                <li><a href="#section-group">Home</a></li>
            </ul>

            <div class="content-holder">
                <div class="section" id="section-group">
                    <div class="group">
                        <app-filegroup></app-filegroup>
                    </div>
                    <div class="group">
                        <app-searchgroup></app-searchgroup>
                    </div>
                    <div class="group">
                        <app-print></app-print>
                    </div>
                    <div class="group">
                        <app-accounts></app-accounts>
                    </div>
                    <div class="group">
                        <app-log></app-log>
                    </div>
                    <div class="group">
                        <div data-role="button-group" data-cls-active="active" (click)="exit()">
                            <button class="ribbon-button">
                               <span class="icon">
                                    <i class="fa fa-x" style="color: red;"></i>
                               </span>
                                   <span class="caption">Exit</span>
                            </button>
                        </div>
                        <span class="title">Exit</span>
                    </div>
                </div>
            </div>
        </nav>
    </div>
    <!-- <app-modaltoolbar></app-modaltoolbar> -->

    <!-- <div class="col-sm-12">

        <div class="row">
            <div class="table-responsive">
                <dx-data-grid width="100%" id="gridContainer" [allowColumnReordering]="true" [dataSource]="orderedItems" keyExpr="Id" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="true" [focusedRowEnabled]="true" [columnMinWidth]="100"
                    (onEditingStart)="editTable($event)" (onFocusedRowChanging)="open(editmodal)">
                    <dxo-editing [allowUpdating]="true" [allowDeleting]="true">
                        <dxo-texts deleteRow="Remove">
                        </dxo-texts>

                    </dxo-editing>
                    <dxo-selection mode="single"></dxo-selection>
                    <dxi-column *ngFor="let data of orderedItemsHead" dataField="{{data}}" [width]="70" caption="{{data}}" [allowEditing]="true" alignment="center">
                    </dxi-column>
                    <dxo-summary></dxo-summary>

                </dx-data-grid>
            </div>
        </div>
    </div> -->
    <div class="col-sm-12">

        <div class="row">
            <div class="table-responsive">

                <dx-data-grid width="100%" id="gridContainer" [allowColumnReordering]="true" [dataSource]="orderedItems" keyExpr="Id" [showColumnLines]="true" [showRowLines]="true" [showBorders]="true" [rowAlternationEnabled]="false" [columnMinWidth]="100" (onRowClick)="open($event, rowmodal)" (onCellPrepared)="onCellPrepared($event)">
                    <!-- <dxo-editing [allowUpdating]="true" [allowDeleting]="true">
                        <dxo-texts deleteRow="Remove">
                        </dxo-texts>

                    </dxo-editing> -->
                    <dxo-selection mode="single"></dxo-selection>

                    <dxi-column *ngFor="let data of orderedItemsHead" dataField="{{data}}" [width]="70" caption="{{data}}" [allowEditing]="true" alignment="center" [visible]="data == 'Id'? false: true" [width]="data == 'Voc Date'? 150:auto" [dataType]="data == 'Voc Date'? 'date':auto" [format]="data == 'Voc Date'? 'dd-MM-yyyy':auto">
                    </dxi-column>
                    <!-- <div *dxTemplate="let Voc Date of 'dateCell'">
                        {{data['Voc Date'] | date: 'dd-MMM-yy'}}
                    </div> -->



                    <dxo-summary>

                    </dxo-summary>

                </dx-data-grid>








            </div>

            <ng-template #rowmodal let-modal modal-xl >
              <div cdkDrag cdkDragRootElement=".maingridmodal">
                <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e ;" >
                    <h4 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">{{pageTitle}} - {{selectrowdata['Voc Date'] | date:'yyyy'}} - {{selectrowdata.Branch}} - {{selectrowdata['Voc Type']}} - {{selectrowdata[ 'Voc No']}}</h4>
                    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
                </div>
                <div class="container" style="padding: 0px;" >
                    <div class="cds-billing">
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12 ribbonmenulink">
                                    <nav data-role="ribbonmenu">
                                        <ul class="tabs-holder">
                                            <li><a href="#section-group">Home</a></li>
                                        </ul>

                                        <div class="content-holder">
                                            <div class="section" id="section-group">
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active">
                                                        <button class="ribbon-button">
                                                                        <span class="icon">
                                                                            <i class="fa-solid fa-file" style="color: #0d4f9e;"></i>
                                                                        </span>
                                                                        <span class="caption">New</span>
                                                                    </button>
                                                        <button class="ribbon-button"(click)="openmodal(editmodal)">
                                                                        <span class="icon" >
                                                                          <i class="fa fa-file-pen" style="color: #0d4f9e;"></i>
                                                                        </span>
                                                                        <span class="caption">Edit</span>
                                                                    </button>
                                                        <button class="ribbon-button">
                                                                        <span class="icon">
                                                                          <i class="fa-solid fa-file-circle-minus" style="color: #0d4f9e;"></i>
                                                                        </span>
                                                                        <span class="caption">Delete</span>
                                                                    </button>
                                                        <button class="ribbon-button">
                                                                        <span class="icon">
                                                                          <i class="fa-solid fa-rotate" style="color: #0d4f9e;"></i>
                                                                        </span>
                                                                        <span class="caption">Refresh</span>
                                                                    </button>
                                                    </div>
                                                    <span class="title">File</span>
                                                </div>
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active">
                                                        <button class="ribbon-button">
                                                                  <span class="icon">
                                                                      <i class="fa-solid fa-magnifying-glass" style="color: #0d4f9e;"></i>
                                                                    </span>
                                                                  <span class="caption">Search</span>
                                                              </button>
                                                        <button class="ribbon-button">
                                                                  <span class="icon">
                                                                    <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                                                                  </span>
                                                                  <span class="caption">First</span>
                                                              </button>
                                                        <button class="ribbon-button">
                                                                  <span class="icon">
                                                                    <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                                                                  </span>
                                                                  <span class="caption">Previous</span>
                                                              </button>
                                                        <button class="ribbon-button">
                                                                  <span class="icon">
                                                                    <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                                                                  </span>
                                                                  <span class="caption">Next</span>
                                                              </button>
                                                        <button class="ribbon-button">
                                                                  <span class="icon">
                                                                    <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                                                                  </span>
                                                                  <span class="caption">Last</span>
                                                              </button>
                                                    </div>
                                                    <span class="title">Search</span>
                                                </div>
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active">

                                                        <button class="ribbon-button">
                                                                <span class="icon">
                                                                  <i class="fa fa-print" style="color: #0d4f9e;"></i>
                                                                </span>
                                                                <span class="caption">Print</span>
                                                            </button>
                                                    </div>
                                                    <span class="title">Print</span>
                                                </div>
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active">

                                                        <button class="ribbon-button" (click)="openmodal(auditmodal)">
                                                            <span class="icon" >
                                                              <i class="fa-solid fa-circle-check" style="color: #0d4f9e;"></i>
                                                            </span>
                                                            <span class="caption">Audit Trial</span>
                                                        </button>
                                                        <button class="ribbon-button">
                                                          <span class="icon">
                                                            <i class="fa-solid fa-file-export" style="color: #0d4f9e;"></i>
                                                          </span>
                                                          <span class="caption">Post</span>
                                                      </button>
                                                    </div>

                                                    <span class="title">Accounts</span>
                                                </div>
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active">

                                                        <button class="ribbon-button">
                                                          <span class="icon">
                                                            <i class="fa-solid fa-envelopes-bulk" style="color: #0d4f9e;"></i>
                                                          </span>
                                                          <span class="caption">Atachment</span>
                                                      </button>
                                                        <button class="ribbon-button" (click)="openmodal(logmodal)">
                                                        <span class="icon" >
                                                          <i class="fa-solid fa-file-lines" style="color: #0d4f9e;"></i>
                                                        </span>
                                                        <span class="caption">Log</span>
                                                    </button>
                                                    </div>

                                                    <span class="title">Log</span>
                                                </div>
                                                <div class="group">
                                                    <div data-role="button-group" data-cls-active="active" (click)="modal.dismiss('Cross click')">
                                                        <button class="ribbon-button">
                             <span class="icon">
                                  <i class="fa fa-x" style="color: red;"></i>
                             </span>
                                 <span class="caption">Exit</span>
                          </button>
                                                    </div>
                                                    <span class="title">Exit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>


                            <div class="row" style="padding-top:20px;">

                                <div class="col-8">
                                    <ng-form>
                                        <app-form-modal [voucherdata]="selectrowdata" [strMainVoctype]="this._strMainVoctype" [strVocType]="this.strVocType"></app-form-modal>
                                    </ng-form>
                                </div>
                                <div class="col-4">
                                    <app-tabs-modal></app-tabs-modal>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12" style="padding-top:30px;">
                                    <app-modaltoolbar></app-modaltoolbar>

                                </div>
                            </div>
                            <div class="row" >
                                <div class="col-12" style="padding-top:30px;" >
                                    <div class="card">
                                        <!-- <app-addproduct-modal></app-addproduct-modal> -->
                                        <app-addproduct-modal [selectrowdata]="this.selectrowdata" [strMainVoctype]="this._strMainVoctype" [strVocType]="this.strVocType"
                                        title="{{pageTitle}} - {{selectrowdata['Voc Date'] | date:'yyyy'}} - {{selectrowdata.Branch}} - {{selectrowdata['Voc Type']}} - {{selectrowdata[ 'Voc No']}}"
                                          ></app-addproduct-modal>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer" style="padding: 5px 10px">
                            <div style="padding-bottom: 0px;">
                                <div style="text-align: right; padding:10px; ">
                                    <button class="btn btn-danger" style="background-color: #0d4f9e;">Save</button>&nbsp;
                                    <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">Close</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

            </ng-template>

            <ng-template #auditmodal let-modal modal-md cdkDrag>

                <div class="modal-header" style="padding: 5px 10px;background-color: #2e2e2e;">
                    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Audit Trial</h5>
                    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class="content-holder">
                        <div class="row">
                            <div class="col-12">
                                 <app-audittrails></app-audittrails>

                            </div>
                        </div>
                    </div>
                </div>



            </ng-template>

            <ng-template #editmodal let-modal modal-md cdkDrag>

                <div class="modal-header" style="padding: 5px 10px;background-color:#2e2e2e ;">
                    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Edit/Delete Confirmation-Transaction</h5>
                    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
                </div>
                <div class="container" style="background-color: #f4f5f7;">
                    <div class="cds-billing">
                        <div class="modal-body" >
                            <div class="content-holder">
                                <div class="row">
                                    <div class="col-12">

                                        <app-mateditdltconf></app-mateditdltconf>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="padding: 5px 10px;background-color: #f4f5f7;">
                    <div style="padding-bottom: 0px;">
                        <div style="text-align: right; padding:10px; ">
                            <button class="btn btn-danger" style="background-color: #0d4f9e;">OK</button>&nbsp;
                            <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">CLOSE</button>
                        </div>
                    </div>
                </div>

            </ng-template>

            <ng-template #logmodal let-modal modal-lg cdkDrag cdkDragRootElement=".tab-full">

                <div class="modal-header" style="padding: 5px 10px;background-color:#2e2e2e ;">
                    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;font-size: medium;">Document Transaction Log</h5>
                    <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: medium;color: #FFFFFF;"></i></button>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class="cds-billing">
                        <div class="content-holder">
                            <div class="row">
                                <div class="col-12">
                                    <app-doctranslog></app-doctranslog>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>





        </div>
