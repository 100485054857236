<form>
    <div class="row">
        <div class="mb-2 col-6">
            <label for="vocno" class="form-label">Password<span class="text-danger">*</span></label>
            <input type="text" class="form-control input-sm" id="password" value="">
        </div>
        <div class="mb-2 col-6">
            <label for="reason" class="form-label">Reason<span class="text-danger">*</span> </label>
            <input type="text" name="reason" class="form-control" id="reason" value="">
        </div>
    </div>
    <div class="row">
        <div class="mb-2 col-12">
            <label for="dscription" class="form-label">Description<span class="text-danger">*</span> </label>
            <input type="text" name="description" class="form-control" id="description" value="">
        </div>
    </div>
</form>
