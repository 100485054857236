import { QuotationCartModule } from './quotation/quotation-cart/quotation-cart.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import {EstimationListComponent} from './theme/shared/components/consigment/estimation-list/estimation-list.component';
import {DiscountAuthorizationComponent} from './theme/shared/components/consigment/discount-authorization/discount-authorization.component'
import { OrderCancellationComponent } from './theme/shared/components/order/order-cancellation/order-cancellation.component';
import { OrderLockUnlockComponent } from './theme/shared/components/order/order-lock-unlock/order-lock-unlock.component';
import { Demo1Component } from './theme/shared/components/demopages/demo1/demo1.component';

const routes: Routes = [

  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path:'demo-page',
        component: Demo1Component
      },
      {
        path: '',
        redirectTo: '/matlogin',
        pathMatch: 'full',
      },
      {
        path: 'salesorders',
        loadChildren: () =>
          import('./salesorder/sales-order-list/sales-order-list.module').then(
            (module) => module.SalesOrderListModule
          ),
      },
      {
        path: 'right-menus',
        loadChildren: () =>
          import('./right-menus/right-menus.module').then(
            (module) => module.RightMenusModule
          ),
      },
      {
        path: 'sales-order',
        loadChildren: () =>
          import('./salesorder/sales-order/sales-order.module').then(
            (module) => module.SalesOrderModule
          ),
      },
      {
        path: 'orderitems',
        loadChildren: () =>
          import('./salesorder/order-items/order-items.module').then(
            (module) => module.OrderItemsModule
          ),
      },
      {
        path: 'orderitemsprint/:orderno/:vocno/:voctype/:bcode/:year',
        loadChildren: () =>
          import('./salesorder/orderitems-print/orderitems-print.module').then(
            (module) => module.OrderitemsPrintModule
          ),
      },

      {
        path: 'itemdetail/:id',
        loadChildren: () =>
          import('./salesorder/item-detail/item-detail.module').then(
            (module) => module.ItemDetailModule
          ),
      },
      {
        path: 'edititemdetail/:id',
        loadChildren: () =>
          import('./salesorder/item-detail-edit/item-detail-edit.module').then(
            (module) => module.ItemDetailEditModule
          ),
      },
      {
        path: 'wishlist',
        loadChildren: () =>
          import('./salesorder/wishlist/wishlist.module').then(
            (module) => module.WishlistModule
          ),
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./salesorder/cart/cart.module').then(
            (module) => module.CartModule
          ),
      },
      {
        path: 'demo-pos',
        loadChildren: () =>
          import('./point-of-sale/demopos/demopos.module').then(
            (module) => module.DemoposModule
          ),
      },
      {
        path: 'point-of-sales',
        loadChildren: () =>
          import('./point-of-sale/pos/pos.module').then(
            (module) => module.PosModule
          ),
      },
      {
        path: 'touch-pos',
        loadChildren: () =>
          import('./point-of-sale/touchpos/touchpos.module').then(
            (module) => module.TouchposModule
          ),
      },
      {
        path: 'touch-pos-demo',
        loadChildren: () =>
          import('./point-of-sale/touchposdemo/touchposdemo.module').then(
            (module) => module.TouchposdemoModule
          ),
      },

      {
        path: 'main-grid-page',
        loadChildren: () =>
          import('./dashboardpages/main-grid-page/main-grid-page.module').then(
            (module) => module.MainGridPageModule
          ),
      },

      {
        path: 'leftmenus/:id',
        loadChildren: () =>
          import('./right-menus/right-menus.module').then(
            (module) => module.RightMenusModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then(
            (module) => module.SettingsModule
          ),
      },
      // Quotation
      {
        path: 'quotation-list',
        loadChildren: () =>
          import('./quotation/quotation-list/quotation-list.module').then(
            (module) => module.QuotationListModule
          ),
      },
      {
        path: 'quotation',
        loadChildren: () =>
          import('./quotation/quotation/quotation.module').then(
            (module) => module.QuotationModule
          ),
      },
      {
        path: 'quotation-cart',
        loadChildren: () =>
          import('./quotation/quotation-cart/quotation-cart.module').then(
            (module) => module.QuotationCartModule
          ),
      },
      {
        path: 'quotationitemdetail/:id',
        loadChildren: () =>
          import(
            './quotation/quotation-item-detail/quotation-item-detail.module'
          ).then((module) => module.QuotationItemDetailModule),
      },
      {
        path: 'quotationedititemdetail/:id',
        loadChildren: () =>
          import(
            './quotation/quotation-item-detail-edit/quotation-item-detail-edit.module'
          ).then((module) => module.QuotationItemDetailEditModule),
      },
      {
        path: 'quotation-order-items',
        loadChildren: () =>
          import(
            './quotation/quotation-order-items/quotation-order-items.module'
          ).then((module) => module.QuotationOrderModule),
      },
      {
        path: 'quotation-wishlist',
        loadChildren: () =>
          import(
            './quotation/quotation-wishlist/quotation-wishlist.module'
          ).then((module) => module.QuotationWishlistModule),
      },

      // {path: 'quotation-cart', component: QuotationCartComponent},
    ],
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./authpages/auth-signin-v2/auth-signin-v2.module').then(
            (module) => module.AuthSigninV2Module
          ),
      },
      {
        path: 'matlogin',
        loadChildren: () =>
          import(
            './authpages/mat-auth-signin-v2/mat-auth-signin-v2.module'
          ).then((module) => module.MatAuthSigninV2Module),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
