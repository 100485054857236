import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-order-cancellation',
  templateUrl: './order-cancellation.component.html',
  styleUrls: ['./order-cancellation.component.scss']
})
export class OrderCancellationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
