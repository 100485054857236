<div data-role="button-group" data-cls-active="active">

    <button class="ribbon-button">
          <span class="icon">
            <i class="fa-solid fa-envelopes-bulk" style="color: #0d4f9e;"></i>
          </span>
          <span class="caption">Atachment</span>
      </button>
    <button class="ribbon-button">
        <span class="icon">
          <i class="fa-solid fa-file-lines" style="color: #0d4f9e;"></i>
        </span>
        <span class="caption">Log</span>
    </button>
</div>

<span class="title">Log</span>