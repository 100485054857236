<div data-role="button-group" data-cls-active="active">

    <button class="ribbon-button">
            <span class="icon">
              <i class="fa-solid fa-circle-check" style="color: #0d4f9e;"></i>
            </span>
            <span class="caption">Audit Trial</span>
        </button>
    <button class="ribbon-button">
          <span class="icon">
            <i class="fa-solid fa-file-export" style="color: #0d4f9e;"></i>
          </span>
          <span class="caption">Post</span>
      </button>
</div>

<span class="title">Accounts</span>