import { Validators } from '@angular/forms';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { SuntechapiService } from 'src/app/suntechapi.service';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit {
  @Input() voucherdata : any;
  @Input()  strMainVoctype: string;
  @Input()  strVocType: string;
  public voucherData : FormGroup;

  voucherFormdata:any =[] ;


  constructor(
    private suntechApi: SuntechapiService,

  private formBuilder: FormBuilder
  ) { }

  getVoucherformdata(){
    
      const strBranchCode = localStorage.getItem('userbranch');
        const strYearMonth = localStorage.getItem('year');
  
     //let result = this.suntechApi.getMainGridDetailFormGridData('ACC', 'PCR',10, 2019);
     let result = this.suntechApi.getMainGridDetailFormGridData(strBranchCode, this.strVocType, this.voucherdata['Voc No'], strYearMonth);
      result.subscribe((data)=>{  
        //console.log('data', data);
              
        if(data != null){
        this.voucherFormdata = data[0]; 
        console.log('data',this.voucherFormdata); 
        }
        //console.log('data', this.voucherFormdata);

            
      });
  
    

  }

  ngOnInit(): void {
    //console.log(this.voucherdata)
   this.getVoucherformdata();

    

  }




}
