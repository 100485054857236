import { Injectable } from '@angular/core';
import { DxPivotGridFieldChooserComponent } from 'devextreme-angular';

export class Bill {
  ACCODE: number;

  DESCRIPTION: string;

  BRANCH: string;

  TOTALCREDITAMT: number;

  TOTALDEBITAMT: number;

  TOTALCRAMT: number;

  TOTALDBAMT: number;
}

const bills: Bill[] = [{
  ACCODE: 1,
  DESCRIPTION: '453855',
  BRANCH: '50',
  TOTALCREDITAMT:5000 ,
  TOTALDEBITAMT: 2000,
  TOTALCRAMT: 100000,
  TOTALDBAMT: 50000,

}, {
  ACCODE: 2,
  DESCRIPTION: '453855',
  BRANCH: '50',
  TOTALCREDITAMT:5000 ,
  TOTALDEBITAMT: 2000,
  TOTALCRAMT: 100000,
  TOTALDBAMT: 50000,
}, {
  ACCODE: 3,
  DESCRIPTION: '453855',
  BRANCH: '50',
  TOTALCREDITAMT:5000 ,
  TOTALDEBITAMT: 2000,
  TOTALCRAMT: 100000,
  TOTALDBAMT: 50000,
}];

@Injectable()
export class Service {
  getBill() {
    return Bill;
  }
}
