import { ActivatedRoute } from '@angular/router';
import { SuntechapiService } from './../../suntechapi.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {Location} from '@angular/common';

import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-main-grid-page',
  templateUrl: './main-grid-page.component.html',
  styleUrls: ['./main-grid-page.component.scss']
})
export class MainGridPageComponent implements OnInit {

  orderedItems = [];
  orderedItemsHead = [];
  slnoLbl: any = 1;
  mainId: number = 1;
  modalReference:any;
  closeResult:any;
  pageTitle: any;

  _strMainVoctype: any;
  strVocType: any;
  private selectrowdata: any;

  constructor(
    private suntechApi: SuntechapiService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private modalService: NgbModal,
    //private activeModal: NgbActiveModal,
    private _location: Location,


  ) {
    this.snackBar.open('Loading...');

      this.getArgs();

  }

  exit() {
    this._location.back();
  }

  getArgs(){
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.pageTitle = params['name'];

      const strBranchCode = localStorage.getItem('userbranch');
      // const strMainVoctype = 'PSP';
      // const strVocType = 'DPU';
      /*const strYearMonth = localStorage.getItem('year');*/
      const strYearMonth = '2008';
      this._strMainVoctype = params['main_voc_type'];
       this.strVocType = params['voc_type'];
      // const strVocType = params['voc_type'];
      // const strYearMonth = '2008';

      this.suntechApi.getTransactionMain(strBranchCode , this._strMainVoctype , this.strVocType , strYearMonth )
      .subscribe(resp => {
      this.snackBar.dismiss();

        console.log(resp.Result);
        // resp.Result.map((s, i)=> s.id = i+1);
      //  console.log(Array.from( Array(resp.Result.length).keys() ) );
          resp.Result.forEach((item, i) => {
            item.Id = i + 1;
          });


        this.orderedItems = resp.Result;
        this.orderedItemsHead = Object.keys(this.orderedItems[0]);
        // console.log(this.orderedItemsHead);
        this.orderedItemsHead.unshift(this.orderedItemsHead.pop())

        // this.orderedItemsHead.sort(function(a, b) {
        //   return a['id'] ? 1 : -1;
        // });

        console.log(this.orderedItemsHead.length)
        console.log(this.orderedItemsHead[0]);
        });

    });
  }


  ngOnInit(): void {
  }

  editTable(event) {
    console.log(event);

  }

  onCellPrepared(e) {
    console.log(e);
     console.log(
      e.column.caption
     );
     console.log(e.rowType);

  //    e.watch((data)=>{
  //     return data.Id;
  // },(newData)=>{
  //      e.cellElement.style.display = "none";
  // })

    // ALIGNMENT
    if(e.rowType == "data" && e.column.caption == "Total Amount"){
      e.cellElement.style.textAlign= "right";
   }
//    if(e.rowType == "data" && e.column.caption == "Voc Date"){
//     e.data.displayValue="dd-MM-yyyy";
//     e.cellElement.style.content= "dd-MM-yyyy";
//     e.column.dataType = "date";
//     e.column.format = "dd-MM-yyyy";
//     e.data['Voc Date']= "dd-MM-yyyy";
//     e.values['3'] = "dd-MM-yyyy";
//  }
  //  if(e.rowType == "data" && e.column.caption == "Id"){

  //   e.cellElement.style.display= "none";
  // }
  // if(e.rowType == "header" && e.column.caption == "Id"){

  //   e.cellElement.style.display= "none";
  // }

  }

   options: NgbModalOptions = {
    size: 'xl',
    backdrop:true,
    windowClass: 'your-custom-dialog-class'
    };
  open(event, content) {
    //console.log(event.data);
    this.selectrowdata = event.data;
     this.modalService.open(content,{
    size: 'xl',
    backdrop:true,
    windowClass: 'maingridmodal'});
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    console.log(this.selectrowdata);
    }
    openmodal(content) {

      this.modalReference = this.modalService.open(content, {
        size: 'md,lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass : 'tab-full',
      });
      this.modalReference.result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );

       }
     private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }



}
