<!-- <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e ;">
    <h4 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Point of Sale Curreny receipt</h4>
    <button type="button" class="ndesign" aria-label="Close" (click)="close()">
        <span aria-hidden="true" style="font-weight: 600; font-size: larger;">×</span>
      </button>
</div> -->
<div cdkDrag cdkDragRootElement=".modal-poscr">
  <div class="modal-header" style="padding: 5px 10px;background-color:#2e2e2e ;">
    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Point of Sale {{title}}  {{this.dataForm.value['INVOICE_NUMBER']}}</h5>
    <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x"
        style="font-size: 20px;color: #FFFFFF;"></i></button>
  </div>
  <div class="container " style="background-color: #ffff; ">
    <div class="content-holder text-center">
      <div class="section" id="section-group" *ngIf="editData =='editData' ">
        <div class="group">
          <!-- <app-searchgroup></app-searchgroup> -->
          <div data-role="button-group" data-cls-active="active">
            <button class="ribbon-button" (click)="moveIndex(0)">
                          <span class="icon">
                            <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">First</span>
                      </button>
            <button class="ribbon-button" (click)="moveIndex(this.dataIndexSecondary >= 1 ?this.dataIndexSecondary-1 : 0)">
                          <span class="icon">
                            <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">Previous</span>
                      </button>
            <button class="ribbon-button" (click)="moveIndex(this.dataIndexSecondary < this.tableData.length -1 ?this.dataIndexSecondary + 1 : this.tableData.length -1)">
                          <span class="icon">
                            <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">Next</span>
                      </button>
            <button class="ribbon-button" (click)="moveIndex(this.tableData.length -1 )">
                          <span class="icon">
                            <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">Last</span>
                      </button>
        </div>
        </div>
      </div>
    </div>
    <!-- <div class="modal-body">f5f6f7 -->
    <div style="margin-top: 12px;">

      <!-- <div class="row" style="margin-top: 15px;">
              <div class="col-2 ">
                  <h5 class="header">Details</h5>
              </div>
          </div> -->
      <form [formGroup]='dataForm'>

        <div class="details">
          <div class="container">
            <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
              <div class="col" style="padding-top:10px;">

                <!-- <div class="row" style="padding-top:20px;">
                  <div class="col"> -->
                <!-- <ng-form> -->
                <!-- <mat-tab> -->
                <div class="form">
                  <mat-form-field>
                    <mat-label>Branch</mat-label>
                    <input type="input" matInput formControlName="branchCode">
                  </mat-form-field>

                </div>
                <!--  </mat-tab>         -->
                <div class="form">

                  <mat-form-field>
                    <mat-label>Mode On</mat-label>
                    <mat-select formControlName="mode">
                      <mat-option>Cash</mat-option>
                      <mat-option>COD</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-icon matSuffix class="srchicon" (click)="openlookup()">search</mat-icon>
                    <input matInput type="search" placeholder="search here">
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text">
                  </mat-form-field>
                </div>

                <div class="form">

                  <mat-form-field>
                    <mat-label>Debit Account</mat-label>
                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                    <input matInput type="search" placeholder="search here">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput type="text">
                  </mat-form-field>
                </div>
                <div class="form">

                  <mat-form-field>
                    <mat-label>Currency</mat-label>
                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                    <input matInput type="search" formControlName="currency" placeholder="search here">
                  </mat-form-field>

                  <mat-form-field>
                    <input matInput type="text">
                  </mat-form-field>
                  <mat-form-field style="width: 20%;">
                    <mat-label>Amount Fc</mat-label>
                    <input type="input" matInput formControlName="amountFc">
                  </mat-form-field>
                  <mat-form-field style="width: 20%;">
                    <mat-label>Amount Lc</mat-label>
                    <input type="input" matInput formControlName="amountLc">
                  </mat-form-field>
                </div>

                <div class="form">
                  <mat-form-field>
                    <mat-label>Remarks</mat-label>
                    <input type="input" matInput formControlName="remarks">
                  </mat-form-field>
                  <!-- </ng-form> -->
                </div>

                <!-- </div>
      </div> -->

              </div>
            </div>
          </div>

          <div style="padding-top:2px; border: solid 0.5px;border-color: #e1dddd;margin-top: 10px;">
            <div class="container">
              <div class="details">
                <div class="row" style="background-color: #dee1e59e; margin-bottom: 20px;">
                  <div class="col">
                    <h5 style="text-align:center;font-weight:400;font-size: 18px;">VAT Details</h5>
                  </div>
                </div>

                <div class="row">
                  <div class="col-5">
                    <div class="form">
                      <mat-form-field>
                        <mat-label>VAT No</mat-label>
                        <input type="input" matInput>
                      </mat-form-field>
                    </div>
                    <div class="form">
                      <mat-form-field>
                        <mat-label>HSN Code</mat-label>
                        <input type="input" matInput>
                      </mat-form-field>
                    </div>
                    <div class="form">
                      <mat-form-field>
                        <mat-label>Invoice No</mat-label>
                        <input type="input" matInput formControlName="invoiceNo">
                      </mat-form-field>
                    </div>
                    <div class="form">
                      <mat-form-field>
                        <mat-label>Invoice Date</mat-label>
                        <input type="date" matInput formControlName="invoiceDate">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row" style="padding-bottom: 10px;">
                      <div class="col-7">
                        <button style="width: 40%;border: solid 1px;" disabled="">Type</button>
                        <!-- </div>
                  <div  class="col-1"> -->
                        <button style="width: 13%;border: solid 1px;" disabled="">%</button>
                        <!-- </div>
                  <div  class="col-3"> -->
                        <button style="width: 31%;border: solid 1px;" disabled="">Amount</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="form">
                          <mat-form-field>
                            <mat-label>Vat %</mat-label>
                            <input type="input" matInput>
                          </mat-form-field>
                        </div>
                        <div class="form">
                          <mat-form-field>
                            <mat-label>Vat CC</mat-label>
                            <input type="input" matInput>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form">
                          <mat-form-field>
                            <mat-label>Total FC</mat-label>
                            <input type="input" matInput>
                          </mat-form-field>
                        </div>
                        <div class="form">
                          <mat-form-field>
                            <mat-label>Total LC</mat-label>
                            <input type="input" matInput>
                          </mat-form-field>
                        </div>
                        <div class="form">
                          <mat-form-field>
                            <mat-label>Header VAT Amount</mat-label>
                            <input type="input" matInput formControlName="headerVatAmt">
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" style="padding: 5px 10px">
            <div style="padding-bottom: 0px;">
              <div style="text-align: right; padding:10px; ">
                <button class="btn btn-danger" style="background-color: #0d4f9e;">Continue</button>&nbsp;
                <button class="btn btn-danger" style="background-color: #0d4f9e;">Save</button>&nbsp;
                <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="close()">Cancel</button>
                <!-- <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">Cancel</button> -->
              </div>
            </div>
          </div>
        </div>
      </form>

      <!-- </div> -->
    </div>
    </div>

