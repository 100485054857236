import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consigment-stock',
  templateUrl: './consigment-stock.component.html',
  styleUrls: ['./consigment-stock.component.scss']
})
export class ConsigmentStockComponent implements OnInit {
  selectedItemKeys:any[] = [];
  consigmentstockhead:any[] = ['Stock Code','From','Supplier','Curr.Code','Curr.Rate','Stock Qty','Rtn/Pur','Rate','Disc %','Net Rate','MkgCost','LandedCost','AllocationRef'];

  constructor() {
    console.log('works')
  }

  ngOnInit(): void {
  }
  selectionChanged(data: any) {
    this.selectedItemKeys = data.selectedRowKeys;
  }

}
