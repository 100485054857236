import { ModalModule } from '../modal/modal.module';
import { LogComponent } from './log/log.component';
import { AccountsComponent } from './accounts/accounts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilegroupComponent } from './filegroup/filegroup.component';
import { SearchgroupComponent } from './searchgroup/searchgroup.component';
import { PrintComponent } from './print/print.component';



@NgModule({
  declarations: [FilegroupComponent, SearchgroupComponent, PrintComponent,AccountsComponent,LogComponent],
  imports: [
    CommonModule,
    ModalModule
  ],
  exports: [
    FilegroupComponent,
    SearchgroupComponent,
    PrintComponent,
    AccountsComponent,
    LogComponent
  ]
})
export class TexteditNavbarModule { }
