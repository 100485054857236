import { Injectable } from '@angular/core';
import { DxPivotGridFieldChooserComponent } from 'devextreme-angular';

export class Customer {
  UNIQNO: number;

  srno: string;

  stock: string;

  division: string;

  pcs: string;

  gross: number;

  stone: string;

  netwt: string;

  purity: string;

  purewt:string;

  ozwt:string;

  charges: string;
}

const customers: Customer[] = [{
  UNIQNO: 1,
  srno: '453855',
  stock: '50',
  division: 'Gold',
  pcs: '2',
  gross: 15,
  stone: '2',
  netwt: '12',
  purity:'95%',
  purewt: '10',
  ozwt: '2',
  charges: '12500.00',
}, {
  UNIQNO: 2,
  srno: '453852',
  stock: '23',
  division: 'Diomond',
  pcs: '5',
  gross: 8,
  stone: '2',
  netwt: '2',
  purity:'92%',
  purewt: '1',
  ozwt: '2',
  charges: '105200.00',
}, {
  UNIQNO: 3,
  srno: '453689',
  stock: '100',
  division: 'Plattinum',
  pcs: '8',
  gross: 18,
  stone: '5',
  netwt: '8',
  purity:'98%',
  purewt: '7.5',
  ozwt: '2',
  charges: '500000.00',
}];

@Injectable()
export class Service {
  getCustomers() {
    return customers;
  }
}


