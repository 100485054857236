<div data-role="button-group" data-cls-active="active">
    <button class="ribbon-button">
                        <span class="icon">
                            <i class="fa-solid fa-file" style="color: #0d4f9e;"></i>
                        </span>
                        <span class="caption">New</span>
                    </button>
    <button class="ribbon-button">
                        <span class="icon">
                          <i class="fa fa-file-pen" style="color: #0d4f9e;"></i>
                        </span>
                        <span class="caption">Edit</span>
                    </button>
    <button class="ribbon-button">
                        <span class="icon">
                          <i class="fa-solid fa-file-circle-minus" style="color: #0d4f9e;"></i>
                        </span>
                        <span class="caption">Delete</span>
                    </button>
    <button class="ribbon-button">
                        <span class="icon">
                          <i class="fa-solid fa-rotate" style="color: #0d4f9e;"></i>
                        </span>
                        <span class="caption">Refresh</span>
                    </button>
</div>
<span class="title">File</span>

<ng-template #addmodal let-modal modal-xl>

    <div class="modal-header" style="padding: 5px 10px;background-color:#0d4f9e ;">
        <h4 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Metal Receipt</h4>
        <button type="button" class="btn btn-close" style="border-color: none;" (click)="modal.dismiss('Cross click')"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
    </div>
    <div class="container">
        <div class="cds-billing">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12 ribbonmenulink">
                        <nav data-role="ribbonmenu">
                            <ul class="tabs-holder">
                                <li><a href="#section-group">Home</a></li>
                            </ul>

                            <div class="content-holder">
                                <div class="section" id="section-group">
                                    <div class="group">
                                        <app-filegroup></app-filegroup>
                                    </div>
                                    <div class="group">
                                        <app-searchgroup></app-searchgroup>
                                    </div>
                                    <div class="group">
                                        <app-print></app-print>
                                    </div>
                                    <div class="group">
                                        <app-accounts></app-accounts>
                                    </div>
                                    <div class="group">
                                        <app-log></app-log>
                                    </div>
                                    <div class="group">
                                        <div data-role="button-group" data-cls-active="active">
                                            <button class="ribbon-button">
                           <span class="icon">
                                <i class="fa fa-x" style="color: red;"></i>
                           </span>
                               <span class="caption">Exit</span>
                        </button>
                                        </div>
                                        <span class="title">Exit</span>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>


                <div class="row" style="padding-top:20px;">

                    <div class="col-8">
                        <ng-form>
                            <app-form-modal></app-form-modal>
                        </ng-form>
                    </div>
                    <div class="col-4">
                        <app-tabs-modal></app-tabs-modal>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-modaltoolbar></app-modaltoolbar>

                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="padding-top:20px;">
                        <div class="card">
                            <app-addproduct-modal></app-addproduct-modal>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="padding: 5px 10px">
                <div style="padding-bottom: 0px;">
                    <div style="text-align: right; padding:10px; ">
                        <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">Close</button>&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>






</ng-template>
