import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OrderLockUnlockComponent } from '../../order/order-lock-unlock/order-lock-unlock.component';

@Component({
  selector: 'app-demo1',
  templateUrl: './demo1.component.html',
  styleUrls: ['./demo1.component.scss']
})
export class Demo1Component implements OnInit {
  modalReference:any;
  closeResult:any;
  pageTitle: any;

  constructor(
    private modalService: NgbModal,
  ){}

  ngOnInit(): void {
  }

  openmodal(content) {

    this.modalReference = this.modalService.open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      windowClass : 'tab-full',
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

     }
   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openlockunlock() {
    this.modalService.open(OrderLockUnlockComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass: 'order-lock-unlock'
    });
  }

}
