import { ReciptsModule } from './../recipts/recipts.module';

import { ModaltoolbarComponent } from './modaltoolbar/modaltoolbar.component';
import { TabsModalComponent } from './tabs-modal/tabs-modal.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { AnimationModalComponent } from './animation-modal/animation-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddproductModalComponent } from './addproduct-modal/addproduct-modal.component';
import { DxDataGridModule } from 'devextreme-angular';
import { MatposcrComponent } from '../matreceipts/matposcr/matposcr.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';






@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    DxDataGridModule,
    ReciptsModule,
    MatTabsModule,MatButtonModule,MatRippleModule,MatFormFieldModule,MatInputModule,MatSelectModule,MatIconModule

  ],
  declarations: [UiModalComponent, AnimationModalComponent,FormModalComponent,TabsModalComponent,ModaltoolbarComponent,AddproductModalComponent],
  exports: [UiModalComponent, AnimationModalComponent,FormModalComponent,TabsModalComponent,ModaltoolbarComponent,AddproductModalComponent],
  entryComponents:[MatposcrComponent]
})
export class ModalModule { }
