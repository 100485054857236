import { NgModule, Component, enableProdMode, Input, SimpleChanges } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DxDataGridModule } from 'devextreme-angular';
import { SuntechapiService } from 'src/app/suntechapi.service';
import { MatposcrComponent } from '../../matreceipts/matposcr/matposcr.component';
import { Customer, Service } from '../modal.service';


// if (!/localhost/.test(document.location.host)) {
//   enableProdMode();
// }

@Component({
  selector: 'app-addproduct-modal',
  templateUrl: './addproduct-modal.component.html',
  styleUrls: ['./addproduct-modal.component.scss'],
  providers: [Service],
})
export class AddproductModalComponent {

  customers: Customer[];
  @Input()  strMainVoctype: string;
  @Input()  strVocType: string;
  @Input()  selectrowdata: any;
  @Input()  title: any;

  tableHeadings: any;
  tableHeadingsTxt: any;
  currencyReceiptDetails: any = [];
  tableHeadingsFirst: any;
  gridLoadingTxt: any = "Loading...";
  modalRef: any;

  constructor(
    service: Service,
    private suntechApi: SuntechapiService,
    public modalService: NgbModal
    ) {

    this.customers = service.getCustomers();
    console.log(this.customers);
  }

  onCellPrepared(e) {
    // console.log(e);
    //  console.log(
    //   e.column.caption
    //  );
    //  console.log(e.rowType);

    // ALIGNMENT
    if(e.rowType == "data" && e.columnIndex == 11){
      e.cellElement.style.textAlign= "right";
   }
  }

  getTransactionDetailFormGrid(){
    console.log('====================================');
    console.log('getTransactionDetailFormGrid');
    console.log('====================================');
    const strBranchCode = localStorage.getItem('userbranch');

    // console.log(this.selectrowdata);


    // let result = this.suntechApi.getTransactionDetailFormGrid(strBranchCode, this.strMainVoctype, this.strVocType);
    let result = this.suntechApi.getTransactionDetailFormGrid(strBranchCode, this.strMainVoctype, this.selectrowdata['Voc Type']);
    result.subscribe((data)=>{
        this.getMainGridDetailFormGridData();

      let values = data?.Result;
      if(values != 'No record'){
        console.log(values);
        this.tableHeadings = values.filter((val)=>val.VISIBLE == true).sort((a, b)=>  a.DISPLAY_ODER > b.DISPLAY_ODER && 1 || -1);
        console.log('====================================');
        console.log(this.tableHeadings);
        this.tableHeadingsTxt = this.tableHeadings.map(data=> data.FIELD_NAME);
        // this.tableHeadingsTxt = this.tableHeadings.map(data=> data.DISPLAY_NAME);
        console.log(this.tableHeadingsTxt);
        this.tableHeadingsFirst = this.tableHeadings['0'].FIELD_NAME
        console.log(this.tableHeadingsFirst);


        console.log('====================================');
      }

    })

  }

  getMainGridDetailFormGridData(){
    const strBranchCode = localStorage.getItem('userbranch');
      const strYearMonth = localStorage.getItem('year');

   //let result = this.suntechApi.getMainGridDetailFormGridData('ACC', 'PCR',10, 2019);
   let result = this.suntechApi.getMainGridDetailFormGridData(strBranchCode, this.strVocType, this.selectrowdata['Voc No'], strYearMonth);
    result.subscribe((data)=>{
      // console.log('====================================');
      // console.log(data);
      // console.log('====================================');
      this.gridLoadingTxt = "";

      if(data != null){
        this.currencyReceiptDetails = data[0].CurrencyReceiptDetails
      }

      // console.table(this.tableHeadings);
      // console.table(this.currencyReceiptDetails);
    });

  }
  openmatmodal(tableData){
    // console.log('====================================');
    // console.log(tableData);
    // console.log('====================================');
    this.modalRef = this.modalService.open(MatposcrComponent, {
      size: 'lg',
      backdrop: true,
      keyboard: false,
      windowClass : 'modal-poscr'
  });
  this.modalRef.componentInstance.tableData = this.currencyReceiptDetails;
  this.modalRef.componentInstance.dataIndex = tableData.dataIndex;
  this.modalRef.componentInstance.title = this.title;
  this.modalRef.componentInstance.editData = 'editData';

  // this.modalRef.componentInstance.tableData = tableData.data;

  }

ngOnInit(): void {
  console.log(this.strMainVoctype);
  console.log(this.strVocType);
  this.getTransactionDetailFormGrid();
}

ngOnChanges(changes: SimpleChanges): void {
  console.log(changes);
  console.log(this.strMainVoctype);
  console.log(this.strVocType);
  // this.getTransactionDetailFormGrid();

}


}
