<div cdkDrag cdkDragRootElement=".modal-custom-lookup">
<div class="modal-header" style="padding: 5px 10px;background-color: #2e2e2e;cursor: all-scroll;" >
    <h5 class="modal-title" id="modal-dia-metal" style="color:#FFFFFF;">Lookup Filter</h5>
    <button type="button" class="btn btn-close" style="border-color: none;" (click)="close()"><i class="feather icon-x" style="font-size: 20px;color: #FFFFFF;"></i></button>
</div>
<div class="modal-body" style="padding: 0px;padding-top: 8px;">
    <div class="content-holder">
        <div class="row">
            <div class="col-12">
                <!-- <app-audittrails></app-audittrails> -->
                <!-- <h6>lookups</h6> -->
                <!-- <dx-data-grid [columns]="['ACCODE','DESCRIPTION', 'BRANCH', 'TOTALCREDITAMT', 'TOTALDEBITAMT', 'TOTALCRAMT', 'TOTALDBAMT']" [showBorders]="true">
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxi-column [allowFiltering]="false" ... ></dxi-column>
                </dx-data-grid> -->
                <dx-data-grid
                    id="gridContainer"
                    [dataSource]="lookupdetails"
                    width="100%"
                    keyExpr="Id"
                    [showBorders]="true"
                    (onCellPrepared)="onCellPrepared($event)"
                    noDataText="loading..."
                     >
                    <!-- <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter> -->
                    <dxo-search-panel [visible]="true" >
                        <dxo-margin
                        [top]="20"
                        [bottom]="20"
                        [left]="30"
                        [right]="30">
                    </dxo-margin>
                    </dxo-search-panel>
                    <dxo-paging [pageSize]="10"> </dxo-paging>
                    <dxo-pager
                    [visible]="true"
                    [showNavigationButtons]="showNavButtons"
                  >
                  </dxo-pager>
                    <!-- <dxo-filter-row
                    [visible]="showFilterRow"
                    [applyFilter]="currentFilter"
                  ></dxo-filter-row>
                  <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter> -->
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxi-column *ngFor="let data of lookupdetailshead"
                    [visible]="data == 'Id'? false: true"
                    [width]="data == 'Stock Code'? 150:auto "
                    dataField="{{data}}" caption="{{data}}" [allowEditing]="true" alignment="center" selectedFilterOperation="contains"
                    [filterOperations]="[]">
                    </dxi-column>

                </dx-data-grid>
            </div>
        </div>
    </div>
</div>
</div>
