import { Component, OnInit, enableProdMode } from '@angular/core';
import { Bill, Service } from '../receipt.service';

if (!/localhost/.test(document.location.host)) {
  enableProdMode();
}

@Component({
  selector: 'app-audittrails',
  templateUrl: './audittrails.component.html',
  styleUrls: ['./audittrails.component.scss'],
  providers: [Service],
})
export class AudittrailsComponent {

  bills: Bill[] | any;

  constructor(service: Service) {
    this.bills = service.getBill();
    console.log(this.bills);
  }

}
