<dx-data-grid id="gridContainer" [dataSource]="bills" keyExpr="ACCODE" [columns]="['ACCODE','DESCRIPTION', 'BRANCH', 'TOTALCREDITAMT', 'TOTALDEBITAMT', 'TOTALCRAMT', 'TOTALDBAMT']" [showBorders]="true">

</dx-data-grid>
<div class="row" style="padding-bottom: 10px; float:right; padding-top: 2px;">
    <div class="col-12">
        <button style="width: 30%;border: solid 1px;" disabled=""><span style="color: black;">Total</span></button>
        <!-- </div>
  <div  class="col-1"> -->
        <input style="width: 35%;border: solid 1px;" disabled="">
        <!-- </div>
  <div  class="col-3"> -->
        <input style="width: 35%;border: solid 1px;" disabled="">
    </div>
</div>
