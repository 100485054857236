<div class="container">
<div class="card">
  <div style="padding-top:2px; border: solid 0.5px;border-color: #e1dddd;margin-top: 10px;">
    <div class="container">
      <div class="details">


        <div class="row">
          <div class="col-md-6">
            <div class="form">
              <mat-form-field style="width:80%">
                <mat-label>item</mat-label>
                <input type="input" matInput>
              </mat-form-field>
            </div>
            <div class="form" >
              <mat-form-field style="width:80%">
                <mat-label>Weight</mat-label>
                <input type="input" matInput>
              </mat-form-field>
            </div>
            <div class="form">
              <mat-form-field style="width:80%">
                <mat-label>Pcs</mat-label>
                <input type="input" matInput formControlName="invoiceNo">
              </mat-form-field>
            </div>
            <div class="form">
              <mat-form-field style="width:80%">
                <mat-label>Rate</mat-label>
                <input type="input" matInput formControlName="invoiceDate">
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">

            <div class="row">
              <div class="col-6">
                <div class="form">
                  <mat-form-field style="width:80%">
                    <mat-label>User Name</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field style="width:80%">
                    <mat-label>Password</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
            <div class="col-12" style="padding-left: 5%;">
              <div class="row" >
                <mat-form-field style="width: 80%;">
                  <mat-label>Remarks</mat-label>
                  <input type="input" matInput>
                </mat-form-field>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>

</div>
<div class="row" style="padding-bottom: 10px; float:right; padding-top: 5px;">
  <div class="col-12">
    <button class="btn btn-danger" style="background-color: #0d4f9e;">Cancel</button>&nbsp;
    <button class="btn btn-danger" style="background-color: #0d4f9e;">Apply</button>&nbsp;
</div>


</div>
