<div class="container">

    <dx-data-grid id="gridContainer" [dataSource]="bills" keyExpr="Estimation Date"  [columns]="['Estimation Date','Estimation No', 'Sales Man Code', 'Customer Code', 'Custome Mobile', 'Customer Name', 'Amount']" [showBorders]="true">
      <dxo-selection mode="multiple"></dxo-selection>
    </dx-data-grid>
  </div>
  <div class="row" style="padding-bottom: 10px; float:right; padding-top: 5px;">
    <div class="col-12">
      <button class="btn btn-danger" style="background-color: #0d4f9e;">Apply</button>&nbsp;
      <button class="btn btn-danger" style="background-color: #0d4f9e;">Close</button>&nbsp;
    </div>


</div>
