<!-- <a href="javascript:" (click)="this.searchOn = true" class="pop-search"><i class="feather icon-search"></i></a> -->

<div class="dropdown drp-user" ngbDropdown placement="auto">
    <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <!-- <img src="assets/images/user/avatar-1.jpg" class="img-radius wid-40" alt="User-Profile-Image">-->
        <a href="javascript:" (click)="this.moreIcon = true" class="pop-morelink"><i class="feather icon-plus"></i></a>
    </a>
    <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu style="left:20px;width:450px;">
        <div class="col-sm-12">
            <h6 class="m-b-0 p-t-10">Quick Links</h6>
            <div class="row">
                <div class="col-sm-6">
                    <ul class="pro-body">
                        <li><a [routerLink]="['/sales-order']" class="dropdown-item"><i class="feather icon-plus"></i> New Sales Order</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Purchase</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Profile</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Sales Order</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Purchase</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Profile</a></li>
                    </ul>
                </div>
                <div class="col-sm-6">
                    <ul class="pro-body">
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Sales Order</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Purchase</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Profile</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Sales Order</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Purchase</a></li>
                        <li><a href="javascript:" class="dropdown-item"><i class="feather icon-plus"></i> Profile</a></li>
                    </ul>
                </div>
            </div>

        </div>


    </div>
</div>

<div class="search-bar" *ngIf="searchOn">
    <input type="text" class="form-control border-0 shadow-none" placeholder="Search here">
    <button (click)="this.searchOn = false" type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="" *ngIf="moreIcon">


</div>
