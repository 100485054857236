<div class="container">
  <nav data-role="ribbonmenu">
    <ul class="tabs-holder">
        <li><a href="#section-group">Detail</a></li>
    </ul>
  </nav>
    <div class="container" style="background-color: #ffff;">
      <div class="details">
                  <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
                    <div class="col-12">
                      <mat-form-field style="width: 80%;">
                          <mat-label>Name</mat-label>
                          <input type="input"  matInput>
                      </mat-form-field>
                    </div>
                      <div class="col" style="padding-top:5px;">
                          <div class="form">
                              <mat-form-field style="width: 40%;">
                                  <mat-label>Scheme Unique NUmber</mat-label>
                                  <input type="input" value="" matInput>
                              </mat-form-field>
                              <mat-form-field style="width: 40%;padding-left: 3%;">
                                <mat-label>Code</mat-label>
                                <input type="input" matInput>
                            </mat-form-field>
                            </div>






                          <div class="form">
                            <mat-form-field style="width: 40%;">
                              <mat-label>Units</mat-label>
                              <input type="input" matInput>
                          </mat-form-field>
                          <mat-form-field style="width: 40%;padding-left: 3%;">
                            <mat-label>Total Value</mat-label>
                            <input type="input" matInput>
                        </mat-form-field>

                          </div>
                          <div class="form">
                            <mat-form-field style="width: 40%;">
                              <mat-label>Start Date</mat-label>
                              <input matInput [matDatepicker]="picker">
                              <mat-hint>MM/DD/YYYY</mat-hint>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                          <mat-form-field style="width: 40%;padding-left: 3%;">
                            <mat-label>End Date</mat-label>
                            <input type="date" matInput>
                        </mat-form-field>

                          </div>


                          <div class="form">
                            <mat-form-field style="width: 40%;">
                              <mat-label>Branch</mat-label>
                              <input type="input" matInput>
                          </mat-form-field>
                          <mat-form-field style="width: 40%;padding-left: 3%;">
                            <mat-label>Sales Man</mat-label>
                            <input type="input" matInput>
                        </mat-form-field>

                          </div>
                          <div class="form">
                            <mat-form-field style="width: 40%;">
                              <mat-label>Status</mat-label>
                              <mat-select formControlName="mode">
                                <mat-option>Active</mat-option>
                                <mat-option>In Active</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                      </div>

              </div>

      </div>



    <div class="modal-footer" style="padding: 5px 10px">
      <div style="padding-bottom: 0px;">
        <div style="text-align: right; padding:10px; ">

          <button class="btn btn-danger" style="background-color: #0d4f9e;">Continue</button>&nbsp;
          <button class="btn btn-danger" style="background-color: #0d4f9e;" >Save</button>&nbsp;
          <button class="btn btn-danger" style="background-color: #0d4f9e;" >Cancel</button>&nbsp;
        </div>
      </div>



  </div>
