<div class="container">
<nav data-role="ribbonmenu">
  <ul class="tabs-holder">
      <li><a href="#section-group">Home</a></li>
  </ul>

  <div class="content-holder">
      <div class="section" id="section-group">
          <div class="group">
              <div data-role="button-group" data-cls-active="active">
                  <button class="ribbon-button">
                                  <span class="icon">
                                      <i class="fa-solid fa-file" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">New</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon" >
                                    <i class="fa fa-file-pen" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Edit</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon">
                                    <i class="fa-solid fa-file-circle-minus" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Delete</span>
                              </button>
                  <button class="ribbon-button">
                                  <span class="icon">
                                    <i class="fa-solid fa-rotate" style="color: #0d4f9e;"></i>
                                  </span>
                                  <span class="caption">Refresh</span>
                              </button>
              </div>
              <span class="title">File</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">
                  <button class="ribbon-button">
                            <span class="icon">
                                <i class="fa-solid fa-magnifying-glass" style="color: #0d4f9e;"></i>
                              </span>
                            <span class="caption">Search</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-backward-step" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">First</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-caret-left" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Previous</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-caret-right" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Next</span>
                        </button>
                  <button class="ribbon-button">
                            <span class="icon">
                              <i class="fa-solid fa-forward-step" style="color: #0d4f9e;"></i>
                            </span>
                            <span class="caption">Last</span>
                        </button>
              </div>
              <span class="title">Search</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button">
                          <span class="icon">
                            <i class="fa fa-print" style="color: #0d4f9e;"></i>
                          </span>
                          <span class="caption">Print</span>
                      </button>
              </div>
              <span class="title">Print</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button" >
                      <span class="icon" >
                        <i class="fa-solid fa-circle-check" style="color: #0d4f9e;"></i>
                      </span>
                      <span class="caption">Audit Trial</span>
                  </button>
                  <button class="ribbon-button">
                    <span class="icon">
                      <i class="fa-solid fa-file-export" style="color: #0d4f9e;"></i>
                    </span>
                    <span class="caption">Post</span>
                </button>
              </div>

              <span class="title">Accounts</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active">

                  <button class="ribbon-button">
                    <span class="icon">
                      <i class="fa-solid fa-envelopes-bulk" style="color: #0d4f9e;"></i>
                    </span>
                    <span class="caption">Atachment</span>
                </button>
                  <button class="ribbon-button" >
                  <span class="icon" >
                    <i class="fa-solid fa-file-lines" style="color: #0d4f9e;"></i>
                  </span>
                  <span class="caption">Log</span>
              </button>
              </div>

              <span class="title">Log</span>
          </div>
          <div class="group">
              <div data-role="button-group" data-cls-active="active" >
                  <button class="ribbon-button">
<span class="icon">
<i class="fa fa-x" style="color: red;"></i>
</span>
<span class="caption">Exit</span>
</button>
              </div>
              <span class="title">Exit</span>
          </div>
      </div>
  </div>
</nav>

  <div class="container" style="background-color: #ffff;">

          <div class="details">

                      <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
                          <div class="col" style="padding-top:10px;">
                              <div class="form">
                                  <!-- <mat-form-field style="width: 25%;">
                                      <mat-label>Voucher Type</mat-label>
                                      <input type="input" value="" matInput>
                                  </mat-form-field> -->
                                  <mat-form-field style="width: 40%;">
                                      <mat-label>Voucher Type</mat-label>
                                      <input type="input" matInput>
                                  </mat-form-field>
                                  <mat-form-field style="padding-left: 2%;">
                                    <mat-label>Customer</mat-label>
                                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                                        <input matInput type="search" placeholder="search here">
                                </mat-form-field>
                                <mat-form-field style="padding-left: 2%;">
                                  <mat-label >Order Date</mat-label>
                                  <input type="date" matInput formControlName="invoiceDate">
                                </mat-form-field>

                              </div>
                              <div class="form">
                                  <mat-form-field style="width: 40%;">
                                      <mat-label>Voucher Date</mat-label>
                                      <input type="input"  matInput>
                                  </mat-form-field>
                                  <mat-form-field style="padding-left: 2%;">
                                    <mat-label>Order#</mat-label>
                                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                                        <input matInput type="search" placeholder="search here">
                                </mat-form-field>
                                <mat-form-field style="padding-left: 2%;">
                                  <mat-label>Order Amount</mat-label>
                                  <input type="input"  matInput>
                              </mat-form-field>

                              </div>

                              <div class="form">
                                  <mat-form-field style="width: 40%;">
                                      <mat-label>Entered By</mat-label>
                                      <mat-icon matSuffix class="srchicon">search</mat-icon>
                                          <input matInput type="search" value="" placeholder="search here">
                                  </mat-form-field>
                                  <mat-form-field style="padding-left: 2%;">
                                    <mat-label style="font-weight: 2%;">Journal Ref#</mat-label>
                                    <input type="input"  matInput>
                                </mat-form-field>
                                <mat-form-field style="padding-left: 2%;">
                                  <mat-label>Adv Received</mat-label>
                                  <input type="input"  matInput>
                              </mat-form-field>
                              </div>
                          </div>

                  </div>
          </div>

  </div>
  <div style="padding-top:2px; border: solid 0.5px;border-color: #e1dddd;margin-top: 10px;">
    <div class="container">
      <div class="details">
        <div class="row" style="background-color: #0d4f9e; margin-bottom: 20px;">
          <div class="col">
            <h5 style="text-align:center;font-weight:400;font-size: 18px;color:#e1dddd ;">Order Details</h5>
          </div>
        </div>

        <div class="row">
          <div class="col-5">
            <div class="form">
              <mat-form-field>
                <mat-label>Mode Of Refund</mat-label>
                <mat-select formControlName="mode">
                  <mat-option>Online</mat-option>
                  <mat-option>Offline</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form">
              <mat-form-field>
                <mat-label>A/C</mat-label>
                <input type="input" matInput>
              </mat-form-field>
            </div>
            <div class="form">
              <mat-form-field>
                <mat-label>Currency</mat-label>
                <input type="input" matInput formControlName="invoiceNo">
              </mat-form-field>
            </div>
            <div class="form">
              <mat-form-field>
                <mat-label>Order Age</mat-label>
                <input type="input" matInput formControlName="invoiceNo">
              </mat-form-field>
              <mat-form-field style="padding-left: 2%;">
                <mat-label>No Item</mat-label>
                <input type="input" matInput formControlName="invoiceNo">
              </mat-form-field>
            </div>
          </div>
          <div class="col">
            <div class="row" style="padding-bottom: 10px;">
              <div class="col-7">
                <button style="width: 40%;background-color: #0d4f9e;border: solid 1px;color:#e1dddd ;" disabled="">Cheque Details</button>

              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form">
                  <mat-form-field>
                    <mat-label>Cheque No</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field>
                    <mat-label>Cheque Date</mat-label>
                    <input type="date" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field>
                    <mat-label>Cheque Bank</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field>
                    <mat-label>Dep Bank</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-6">
                <div class="form">
                  <mat-form-field>
                    <mat-label>Cancellation Charges</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field>
                    <mat-label>Refund Amount LC</mat-label>
                    <input type="input" matInput>
                  </mat-form-field>
                </div>
                <div class="form">
                  <mat-form-field>
                    <mat-label>Order Cancel A/C</mat-label>
                    <input type="input" matInput formControlName="headerVatAmt">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="padding: 5px 10px">
    <div style="padding-bottom: 0px;">
      <div style="text-align: right; padding:10px; ">

        <button class="btn btn-danger" style="background-color: #0d4f9e;">Save</button>&nbsp;
        <button class="btn btn-danger" style="background-color: #0d4f9e;" >Cancel</button>
        <!-- <button class="btn btn-danger" style="background-color: #0d4f9e;" (click)="modal.dismiss('Cross click')">Cancel</button> -->
      </div>
    </div>
  </div>


</div>
