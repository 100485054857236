<!-- <div class="card">
    <div class="card-body">
        <form>
            <div class="row">
                <div class="mb-2 col-6">
                    <label for="vocno" class="form-label">Voucher Type<span class="text-danger">*</span></label>
                    <div class="input-group">

                        <input type="text" class="form-control
                              input-sm" value="{{voucherdata[ 'Voc Type']}}" />
                        <span class="input-group-btn" style="width:0px;"></span>
                        <input type="text" class="form-control
                              input-sm" value="{{voucherdata[ 'Voc No']}}" />
                    </div>
                   
                </div>
                <div class="mb-2 col-6">
                    <label for="reff" class="form-label">#Refference<span class="text-danger">*</span> </label>
                    <input type="text" name="reff" class="form-control" id="reff" value="">
                </div>
            </div>
            <div class=" row ">
                <div class="mb-2 col-6 ">
                    <label for="vocdate " class="form-label ">Voucher Date<span class="text-danger ">*</span> </label>
                    <input type="Date " name="vocdate " class="form-control " id="vocdate " value="{{voucherdata['Voc Date'] | date:'dd-MM-yyyy'}}">
                </div>
                <div class="mb-2 col-6 ">
                    <label for="rdate " class="form-label ">Reffer Date<span class="text-danger ">*</span> </label>
                    <input type="Date " name="rdate " class="form-control " id="rdate " value="">
                </div>
            </div>
            <div class="row ">
                <div class="mb-2 col-6 ">
                    <label for="pcode " class="form-label ">Party Code <span class="text-danger ">*</span></label>
                    <input id="pcode " type="search " class="form-control " name="pcode " required value="{{voucherdata[ 'Party Code']}}">

                </div>

            </div>
            <div class="row ">
                <div class="mb-2 col-6 ">
                    <label for="entered " class="form-label ">Entered By <span class="text-danger ">*</span></label>
                    <input id="entered " type="search " class="form-control " name="entered " required value="{{voucherdata['Id']}}">

                </div>

            </div>
        </form>
    </div>
</div> -->
<div class="card">
<div class="container" style="background-color: #ffff;">    
              
        <div class="details">
                
                    <div class="row finput" style="border: solid 0.5px;border-color: #e1dddd;">
                        <div class="col" style="padding-top:10px;">
                            <div class="form">
                                <!-- <mat-form-field style="width: 25%;">
                                    <mat-label>Voucher Type</mat-label>
                                    <input type="input" value="" matInput>
                                </mat-form-field> -->
                                <mat-form-field style="width: 52%;">
                                    <mat-label>Voucher No</mat-label>
                                    <input type="input" value="{{voucherFormdata.VOCNO}}" matInput>
                                </mat-form-field>
                                <mat-form-field style="width: 40%;">
                                    <mat-label>#Refference</mat-label>
                                    <input type="input" matInput>
                                </mat-form-field>

                            </div>
                            <div class="form">
                                <mat-form-field style="width: 52%;">
                                    <mat-label>Voucher Date</mat-label>
                                    <input type="input" value="{{voucherFormdata['VOCDATE'] | date:'dd-MM-yyyy'}}" matInput>
                                </mat-form-field>
                                <mat-form-field style="width: 40%;">
                                    <mat-label>Reffer Date</mat-label>
                                    <input type="input" matInput>
                                </mat-form-field>                               

                            </div>
                            <div class="form">
                                <mat-form-field>
                                    <mat-label>Party Code</mat-label>
                                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                                        <input matInput type="search" value="{{voucherFormdata.PARTYCODE}}" placeholder="search here">
                                </mat-form-field>
                            </div>
                            <div class="form">
                                <mat-form-field>
                                    <mat-label>Entered By</mat-label>
                                    <mat-icon matSuffix class="srchicon">search</mat-icon>
                                        <input matInput type="search" value="" placeholder="search here">
                                </mat-form-field>
                            </div>
                        </div>
                    
                </div>
        </div>
   
</div>
</div>
