import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatposcrComponent } from '../../matreceipts/matposcr/matposcr.component';


@Component({
  selector: 'app-modaltoolbar',
  templateUrl: './modaltoolbar.component.html',
  styleUrls: ['./modaltoolbar.component.scss']
})
export class ModaltoolbarComponent implements OnInit {
  modalReference:any;
  closeResult:any;
  modalRef: any;




  constructor(
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {
  }

  openmatmodal(){
    this.modalRef = this.modalService.open(MatposcrComponent, {
      size: 'lg',
      windowClass: 'modal-poscr',
  });
  }

  openmodal(content) {

    this.modalReference = this.modalService.open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );

     }
   private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
