import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupComponent } from './lookup/lookup.component';
import { DxDataGridModule, DxSelectBoxModule, DxCheckBoxModule,DxChartModule } from "devextreme-angular";
import { SuntechapiService } from 'src/app/suntechapi.service';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    LookupComponent
  ],
  imports: [
    CommonModule,
    DxDataGridModule, DxSelectBoxModule, DxCheckBoxModule,DxChartModule,DragDropModule
  ],
  exports: [
    LookupComponent
  ],
  providers:[SuntechapiService]
})
export class LookupModule { }
