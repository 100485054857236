<dx-data-grid id="gridContainer" [dataSource]="currencyReceiptDetails" [keyExpr]="tableHeadingsFirst"
  [columns]="tableHeadingsTxt"
  [showBorders]="true"
   (onCellPrepared)="onCellPrepared($event)"
   [noDataText]="gridLoadingTxt"
   (onRowClick)="openmatmodal($event)"
   >
   <dxi-column *ngFor="let data of tableHeadings" [dataField]="data?.FIELD_NAME"  [caption]="data?.DISPLAY_NAME" ></dxi-column>

</dx-data-grid>

