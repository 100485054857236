// import { ILeftMenus } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root',
})
export class SuntechapiService {

  public httpOptions = {
    headers: new HttpHeaders({}),
  };


  public leftmenus: any = [];
  // public leftmenus: ILeftMenus[] = [{
  //   BUTTONID: '1',
  //   BUTTON_VISIBLE: 'True',
  //   ButtonDescription: '&Master ',
  //   ButtonDescriptionCHINESE: '',
  //   ButtonDescriptionJOR: '',
  //   ButtonDescriptionOther: '&Master ',
  //   ButtonDescriptionSAU: '',
  //   ButtonDescriptionTURKISH: '',
  //   ButtonImage: '',
  //   ButtonImagePath: '',
  //   DetailHolder: '1',
  //   DisplayText: '&Master ',
  //   DisplayTextOther: '&Master ',
  //   MENU_CAPTION_ENG: '&Master ',
  //   MENU_DESC_ARABIC_JOR: '',
  //   MENU_DESC_ARABIC_SAU: '',
  //   MENU_ID: '00001',
  //   menuurl: 'master'
  // }];

  rightMenuAllValues : any = [];

  // private dataSource: BehaviorSubject<any> = new BehaviorSubject<any>({'key':[1,2,3]});
  // data: Observable<any> = this.dataSource.asObservable();

  companyParamSubject: Subject<any>;
  constructor(private http: HttpClient) {
    this.companyParamSubject = new Subject<any>();

    console.log('suntechapi service call ...');
    this.httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': 'somekeytokenwillcomehere',
      }),
    };

    if(this.rightMenuAllValues != '')
    this.setRightMenuAll();
  }

  set setLeftMenuItems(val){
    this.leftmenus = val;
    localStorage.setItem('leftmenus', JSON.stringify(this.leftmenus));
    // console.log(this.rightMenuAllValues);
    if(this.rightMenuAllValues != null)
    this.setRightMenuAll();

  }

  get getLeftMenuUItems(){
    return this.leftmenus;
  }

  setRightMenuAll(){
   console.log('------------------setRightMenuAll fun call---------------------');

    const branchCode = localStorage.getItem('userbranch');
    const userName = localStorage.getItem('username')

    this.leftmenus.map(data=> {
   this.getMenusPage(branchCode, userName, data?.BUTTONID, data?.MENU_ID).subscribe((res=> {
       localStorage.setItem('rightMenuId_'+data.BUTTONID, JSON.stringify(res) );
       this.rightMenuAllValues.push({'rightMenuId' :data.BUTTONID ,  res});
       return res;
      }
      ));
     } );
   console.log( this.rightMenuAllValues);

     return this.rightMenuAllValues;
  }

 get getRightMenuAll(){
     return this.setRightMenuAll();
  }

  /* Sales Order Services */


  setCompanyParamSubject(data){
    console.log('setCompanyParamSubject');
    this.companyParamSubject.next(data);
  }

  getBranchMaster(): Observable<any> {
    return this.http.get(baseUrl + 'BranchMaster');
  }

  getSalesPersonMaster(): Observable<any> {
    return this.http.get(baseUrl + 'SalesPersonMaster');
  }

  getOrderType(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=ORDERTYPE%20MASTER');
  }

  getCustomers(): Observable<any> {
    return this.http.get(
      baseUrl + 'AccountMaster?AccountMode=P,R&fields=ACCODE,ACCOUNT_HEAD'
    );
  }

  getCustomerDetails(acCode): Observable<any> {
    return this.http.get(baseUrl + 'AccountMaster?strAccode=' + acCode);
  }

  getAllColors(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=COLOR%20MASTER');
  }

  getAllShapes(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=SHAPE%20MASTER');
  }

  getAllSize(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=SIZE%20MASTER');
  }

  getAllClarity(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=CLARITY%20MASTER');
  }

  getEnamelColors(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=ENAMEL%20COLOR');
  }

  getKarats(): Observable<any> {
    return this.http.get(baseUrl + 'karatMaster');
  }

  getDesignProperties(strDesignCode = 'MB002293'): Observable<any> {
    return this.http.get(
      baseUrl +
        'SalesOrder?strDesignCode=' +
        strDesignCode +
        '&strIsDesignStock=N&strIsComponent=Y&strIsColorSet=N&strIsMetalColor=%22%22'
    );
  }

  getSalesOrderStock(catCode = '000'): Observable<any> {
    return this.http.get(
      baseUrl + 'SalesOrderStockMain?strCatalogueCode=' + catCode
    );
  }

  getCatalogueList(): Observable<any> {
    return this.http.get(baseUrl + 'CatalogueLookup');
  }

  getSalesOrderDetails(orderno,vocno,voctype,bcode,year) : Observable<any> {
    return this.http.get(baseUrl + 'SalesOrder?BRANCH_CODE=' + bcode + '&VOCTYPE=' + voctype + '&VOCNO=' + vocno + '&YEARMONTH=' + year + '&SalesOrderNo=' + orderno + '');
  }

  getProductDetailsbyStockCode(stockCode = 'MR011317'): Observable<any> {
    return this.http.get(
      baseUrl + 'SalesOrderStockMain?strStockCode=' + stockCode
    );
  }

  /*getProducts_SalesOrderEdit(sessionId = ""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }*/

  getProducts_SalesOrderEdit(sessionId = ''): Observable<any> {
    return this.http.get(
      baseUrl + 'SalesOrderEdit/GetsaleorderTemp?SESSION_ID=' + sessionId
    );
  }

  getProducts_SalesOrderEditComponents(sessionId = ''): Observable<any> {
    return this.http.get(
      baseUrl + 'SalesOrderEditComponents?strsessionId=' + sessionId
    );
  }

  getProducts_SalesOrderEditComponents_by_designCode(
    sessionId = '',
    designCode = ''
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'SalesOrderEditComponents?strsessionId=' +
        sessionId +
        '&strDesignCode=' +
        designCode
    );
  }

  /*getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(sessionId = "", designCode="", strUNQ_DESIGN_ID=""): Observable<any> {
    return this.http.get(baseUrl+'SalesOrderEditComponents?strsessionId='+sessionId+"&strDesignCode="+designCode+"&strUNQ_DESIGN_ID="+strUNQ_DESIGN_ID);
  } */

  getProducts_SalesOrderEditComponents_by_designCode_unq_design_id(
    sessionId = '',
    designCode = '',
    strUNQ_DESIGN_ID = ''
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'SalesOrderEditComponents/GetsalesOrderStmtlDetailsTemp?strsessionId=' +
        sessionId +
        '&strDesignCode=' +
        designCode +
        '&strUNQ_DESIGN_ID=' +
        strUNQ_DESIGN_ID
    );
  }

  get_SalesOrderEdit_UID(sessionId, strStockCode) {
    return this.http.get(
      baseUrl +
        'SalesOrderEdit?SESSION_ID=' +
        sessionId +
        '&strStockCode=' +
        strStockCode
    );
  }

  getAllSalesOrders(): Observable<any> {
    return this.http.get(baseUrl + 'SalesOrder');
  }

  /* Delete API */
  /*deleteProduct_SalesOrderEdit(sessionId = "", designCode = "", UniqueDesignID=""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+designCode+'&UniqueDesignID='+UniqueDesignID);
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId);
  }

  deleteProduct_SalesOrderEditComponents(sessionId = "", designCode = "", UNQ_DESIGN_ID=""): Observable<any> {
    return this.http.delete(baseUrl+'SalesOrderEditComponents?SESSION_ID='+sessionId+'&strDesignCode='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID);
  } */

  deleteProduct_SalesOrderEdit(
    sessionId = '',
    designCode = '',
    UniqueDesignID = ''
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'SalesOrderEdit/Delete?SESSION_ID=' +
        sessionId +
        '&DESIGN_CODE=' +
        designCode +
        '&UniqueDesignID=' +
        UniqueDesignID
    );
  }

  deleteProduct_SalesOrderEdit_bySessionID(sessionId = ''): Observable<any> {
    return this.http.get(
      baseUrl + 'SalesOrderEdit/Delete?SESSION_ID=' + sessionId
    );
  }

  deleteProduct_SalesOrderEditComponents(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = ''
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'SalesOrderEditComponents/Delete?SESSION_ID=' +
        sessionId +
        '&strDesignCode=' +
        designCode +
        '&UNQ_DESIGN_ID=' +
        UNQ_DESIGN_ID
    );
  }

  /* Put API */

  /*editProduct_SalesOrder(sessionId = "", designCode = "", UNQ_DESIGN_ID="",COMPSLNO=1, records): Observable<any> {
    return this.http.put(baseUrl+'SalesOrderEdit?SESSION_ID='+sessionId+'&DESIGN_CODE='+ designCode+'&UNQ_DESIGN_ID='+UNQ_DESIGN_ID+'&COMPSLNO='+COMPSLNO, records, this.httpOptions);
  } */

  editProduct_SalesOrder(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = '',
    COMPSLNO = 1,
    records
  ): Observable<any> {
    return this.http.put(
      baseUrl +
        'SalesOrderEdit/UpdateItemAttribute?SESSION_ID=' +
        sessionId +
        '&DESIGN_CODE=' +
        designCode +
        '&UNQ_DESIGN_ID=' +
        UNQ_DESIGN_ID,
      records,
      this.httpOptions
    );
  }

  editProduct_SalesOrderEditComponents(
    sessionId = '',
    designCode = '',
    UNQ_DESIGN_ID = '',
    COMPSLNO = 1,
    records
  ): Observable<any> {
    return this.http.put(
      baseUrl +
        'SalesOrderEditComponents?SESSION_ID=' +
        sessionId +
        '&DESIGN_CODE=' +
        designCode +
        '&UNQ_DESIGN_ID=' +
        UNQ_DESIGN_ID +
        '&COMPSLNO=' +
        COMPSLNO,
      records,
      this.httpOptions
    );
  }

  updateKaratColorinWishlist(SESSION_ID = '', KaratCode = '', Color = '') {
    return this.http.put(
      baseUrl +
        'SalesOrderEdit?SESSION_ID=' +
        SESSION_ID +
        '&KaratCode=' +
        KaratCode +
        '&Color=' +
        Color,
      this.httpOptions
    );
  }

  /* Post API */
  postProductComponents(records): Observable<any> {
    return this.http.post(
      baseUrl + 'SalesOrderEditComponents',
      records,
      this.httpOptions
    );
  }

  postProduct_SalesOrderEdit(record): Observable<any> {
    return this.http.post(baseUrl + 'SalesOrderEdit', record, this.httpOptions);
  }

  postSalesOrder(records): Observable<any> {
    return this.http.post(baseUrl + 'SalesOrder', records, this.httpOptions);
  }

  /* Sales Order Services End */

  /* Company Parameter */
  getCompanyParameters(): Observable<any> {
    return this.http.get(baseUrl + 'CompanyParameters');
  }

  /* Login */
  getUserName(username): Observable<any> {
    return this.http.get(baseUrl + 'UserDetailNetMaster?USERNAME=' + username);
  }

  checkUserNamePassword(username, password): Observable<any> {
    return this.http.get(
      baseUrl +
        'ValidatePassword?strusername=' +
        username +
        '&strPassword=' +
        password
    );
  }

  getUserBranch(username): Observable<any> {
    return this.http.get(baseUrl + 'UseBranchNetMaster?USERNAME=' + username);
  }

  getUserBranchDetails(branchcode): Observable<any> {
    return this.http.get(baseUrl + 'BranchMaster?BranchCode=' + branchcode);
  }

  getUFinancialYear(branchcode, username): Observable<any> {
    return this.http.get(
      baseUrl + 'FinancialYear?branchcode=HO&strusername=Admin'
    );
  }

  /* Menus */
  getLeftMenus(): Observable<any> {
    return this.http.get(
      baseUrl +
        'MenuListLeftPan?strRoleCode=Admin&strusername=Admin&branchcode=HO&strlanguage=ENGLISH&strCountry=SAU'
    );
  }

  getMenusPage(branchCode, userName, btnId, roleId): Observable<any> {
    return this.http.get(
      baseUrl +
        'MenuPage?branchcode=' + branchCode + '&strusername=' + userName + '&strbuttonID=' + btnId + '&strRoleId=' + roleId
    );
  }


  getTransactionMain(strBranchCode, strMainVoctype, strVocType, strYearMonth): Observable<any> {
    return this.http.get(
      baseUrl +
        'TransactionMain?strBranchCode=' + strBranchCode + '&strMainVoctype=' + strMainVoctype + '&strVocType=' + strVocType + '&strYearMonth=' + strYearMonth
    );
  }


  /* POS started on 20-09-2021 */
  getPOS_Stockdetails_withoutcost(stock_code): Observable<any> {
    return this.http.get(
      baseUrl + 'TableViewWithoutCost?STOCK_CODE=' + stock_code
    );
  }

  getPOS_RetailSalesItemImage(stock_code): Observable<any> {
    return this.http.get(
      baseUrl + 'RetailSalesItemImage?StrstockCode=' + stock_code
    );
  }

  getPOS_Stockdetails_branchwise_Metalcost(
    stock_code,
    branch_code
  ): Observable<any> {
    // https://angulardata.sunwebapps.com/api/BranchMetalCost?BMC_SUB_STOCK_CODE=GNK00020&BMC_BRANCH_CODE=DM
    return this.http.get(
      baseUrl +
        'BranchMetalCost?BMC_SUB_STOCK_CODE=' +
        stock_code +
        '&BMC_BRANCH_CODE=' +
        branch_code
    );
  }

  getPOS_Stockdetails_branchwise_withcost(
    stock_code,
    branch_code
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'BranchDiamondCost?BDC_STOCK_CODE=' +
        stock_code +
        '&BDC_BRANCH_CODE=' +
        branch_code
    );
  }

  getDivisionDetails(): Observable<any> {
    return this.http.get(baseUrl + 'DivisionMaster');
  }

  getSettingGrid(voc_type, branch_code): Observable<any> {
    return this.http.get(
      baseUrl +
        'TrnFormGridSettingsBlank?VocType=' +
        voc_type +
        '&BranchCode=' +
        branch_code
    );
  }

  getUserList(): Observable<any> {
    return this.http.get(baseUrl + 'TrnFormGridSettingsBlank/GetuserLsit');
  }

  getPosCustomerList(mobile_number): Observable<any> {
    return this.http.get(baseUrl + 'PosCustomerMaster?MOBILE=' + mobile_number);
  }

  getIdMasterList(): Observable<any> {
    return this.http.get(baseUrl + 'GeneralMaster?Types=ID%20MASTER');
  }

  getCreditCardMaster(): Observable<any> {
    return this.http.get(baseUrl + 'CreditCardMaster');
  }

  getBranchKaratRate(branch_code): Observable<any> {
    return this.http.get(
      baseUrl + 'BranchKaratRate?BRANCH_CODE=' + branch_code
    );
  }

  getPOSStockCodeValidation(
    strstockCode,
    strBranchCode,
    strusername
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'POSStockCodeValidation?strstockCode=' +
        strstockCode +
        '&strBranchCode=' +
        strBranchCode +
        '&strusername=' +
        strusername
    );
  }

  getPOSStockCodeValidation_temp(
    strstockCode,
    strBranchCode,
    strusername
  ): Observable<any> {
    return this.http.get(baseUrl + 'BranchKaratRate?BRANCH_CODE=DMCC');
  }

  getRetailSalesItemVatInfo(branch_code): Observable<any> {
    return this.http.get(
      baseUrl +
        'RetailSalesItemVatInfo?strBranchCode=' +
        branch_code +
        '&strstockCode=22KG&strVocType=POS&strdate=2021-03-22&strpartyCode=%20%20%20%27%27'
    );
  }

  getRetailSReturnLookUp(
    branch_code,
    voctype,
    vocno,
    yearmonth
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'RetailSReturnLookUp?strBranchCode=' +
        branch_code +
        '&strVoctype=' +
        voctype +
        '&intVocNo=' +
        vocno +
        '&stryearmonth=' +
        yearmonth
    );
  }

  getRetailsalesExchangeLookup(): Observable<any> {
    return this.http.get(baseUrl + 'RetailsalesExchangeLookup/');
  }

  getLanguageDictionary(): Observable<any> {
    return this.http.get(baseUrl + 'LanguageDictionary/');
  }

  getLanguageDictionaryWeb(): Observable<any> {
    return this.http.get(baseUrl + 'LanguageDictionaryWeb/');
  }

  getTransactionDetailFormGrid(
    branch_code,
    voctype,
    vocno,
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'TrnsDetailFormGrid?strBranchCode=' +
        branch_code +
        '&strMainVoctype=' +
        voctype +
        '&strVocType=' +
        vocno
    );
  }
   // vignesh api

   getLookup(): Observable<any> {
    return this.http.get(baseUrl + 'LookUp?strLookupId=4&strWhereCondition=WHERE%20ITEM%20=%20%27D%27');
  }

  // end vignesh

  // CurrencyReceipt by PG.Moorthy
  getMainGridDetailFormGridData(
    branch_code,
    voctype,
    vocno,
    year
  ): Observable<any> {
    return this.http.get(
      baseUrl +
        'CurrencyReceipt?BRANCH_CODE=' +
        branch_code +
        '&VOCTYPE=' +
        voctype +
        '&VOCNO=' +
        vocno
        +'&YEARMONTH=' +
        year
    );
  }

  // sendsalesorderdetails(e :any)
  // {
  //   console.log(e);
  //   this.dataSource.next(e);
  // }

}
